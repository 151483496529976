import React, { useState, useContext, useEffect, useRef } from "react";
import GeneralContext from "../GeneralContext/GeneralContext";
import axios from "../../api/axios";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import TextField from "@mui/material/TextField";

import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from "@mui/material/FormControl";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import "dayjs/locale/es";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import PageBody from "../GeneralComponents/PageBody";
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

import ModalStandard from "../GeneralComponents/ModalStandard";
import InputFake from "../GeneralComponents/InputFake";
import { useNavigate } from "react-router-dom";
import InputRut from "../GeneralComponents/InputRut";
import InputFile from "../GeneralComponents/InputFile";

import ContingenciasHiddenPreview from "./ContingenciasHiddenPreview";
import HourglassLoader from "../GeneralComponents/HourglassLoader";

let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);

export default function Contingencias() {
  const { userSesion, setPageTitle, setDrawerHidding } = useContext(GeneralContext);
    const [RBDList, setRBDList] = useState([""]);
    const [rbdLoading, setRbdLoading] = useState(false);

    const [submitConfirm, setSubmitConfirm] = useState(false);
  //const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [eesData, setEesData] = useState(null);
  const [autoCompleteInput, setAutoCompleteInput] = useState("");
  const autoCompleteRef = useRef(null);

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const navigate = useNavigate();
    const [modalError, setModalError] = useState(false);
    const [modalContent, setModalContent] = useState("");


    const rutRef = useRef(null);   
    const evidenceRef = useRef(null); 
    const [evError, setEvError] = useState(false);      
    const [errorRut, setErrorRut] = useState(false);
    const [evidence, setEvidence] = useState();

  const contingenciasRef = useRef();
  const [formData, setFormData] = useState({
    name_emisor: userSesion.nombre,
    rut_emisor: userSesion.rut,
    nombre_colegio: "",
    id_operador: "",
    id_zona: "",
    id_ticket_ayuda: "",
    operator_name: "",
    checked: false,
    ees_temporal_manager: "",
    fecha_hora_solucion: dayjs(),
    fecha_hora_ocurrencia: dayjs(),
    codigo_tipo_evento: "",
    origen: "",
    extension_falla: "",
    severidad: "",
    rbd: "",
    jornada: "",
    texto_falla_operador: "",
    evidence: [],
  });

  const [errorMessages, setErrorMessages] = useState({
    fecha_hora_ocurrencia: "",
    fecha_hora_solucion: "",
  });

    const setFile = (archivo) => {
      setEvError(!archivo ? true : false);
    setEvidence((prevData) => ({
      ...prevData,
      archivo,
    }));
  };

    const handleSwitch = (event) => {
      setFormData({ ...formData, ["checked"]: event.target.checked })
  };
      const handleErrorChange = (error) => {
        setErrorRut(error);
    };

  const validateForm = () => {
    let valid = true;
    const errors = {};
      if (errorRut){
        rutRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        return
    }
    if (!formData.fecha_hora_ocurrencia || !dayjs(formData.fecha_hora_ocurrencia).isValid()) {
      errors.fecha_hora_ocurrencia = "La fecha de inicio es inválida.";
      valid = false;
    } else if (dayjs(formData.fecha_hora_ocurrencia).isAfter(dayjs())) {
      errors.fecha_hora_ocurrencia =
        "La fecha de solución no puede ser una fecha futura.";
      valid = false;
    }

    // Validar fecha_hora_solucion
    if (!formData.fecha_hora_solucion || !dayjs(formData.fecha_hora_solucion).isValid()) {
      errors.fecha_hora_solucion = "La fecha de solución es inválida.";
      valid = false;
    } else if (dayjs(formData.fecha_hora_solucion).isBefore(formData.fecha_hora_ocurrencia)) {
      errors.fecha_hora_solucion =
        "La fecha de solución debe ser posterior a la de inicio.";
      valid = false;
    } else if (dayjs(formData.fecha_hora_solucion).isAfter(dayjs())) {
      errors.fecha_hora_solucion =
        "La fecha de solución no puede ser una fecha futura.";
      valid = false;
    }

    setErrorMessages(errors);
    return valid;
  };

  function getOperator() {
      axios.getOperator(userSesion.rut)
          .then((response) => {
              setFormData({ ...formData, operator_name: response.data.operador, id_operador: response.data.id_operador+"" })
          })
          .catch((error) => {
          })
    }

  const save = async (event) => {
    event.preventDefault();
    if (!evidence){
        setEvError(!evidence ? true : false);
        evidenceRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        return;
    }
    if (error) {
      autoCompleteRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      return; 
    }


    if (validateForm()) {
      handleDownloadPdf();
    }
  };

  const handleDownloadPdf = () => {
      if (!submitConfirm) {
        setSubmitConfirm(!submitConfirm);
        return;
    }

    const element = contingenciasRef.current;
    setLoadingPdf(true);

      setTimeout(() => {
    const element = contingenciasRef.current;
    
    html2canvas(element, { scale: 2 })
      .then((canvas) => {
        setLoading(true);
        const imageData = canvas.toDataURL('image/jpeg', 1);
        
        const pdfWidth = 210;
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
        const pdf = new jsPDF('p', 'mm', [pdfWidth, pdfHeight + 60]);
        
        pdf.setFillColor(255, 255, 255);
        pdf.rect(0, 0, pdfWidth, pdfHeight + 140, 'F');
        pdf.addImage(imageData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
        const pdfBlob = pdf.output('blob');

            const form = new FormData();
            const localFormData = {
                ...formData,
                rbd: autoCompleteInput ? autoCompleteInput : "" ,
                fecha_hora_ocurrencia: formData["fecha_hora_ocurrencia"].locale('es').format('YYYY/MM/DD HH:MM:ss').toString(),
                fecha_hora_solucion: formData["fecha_hora_solucion"].locale('es').format('YYYY/MM/DD HH:MM:ss').toString(),
                evidence: evidence && evidence.archivo ? [evidence.archivo] : [],
            }

            form.append("data", JSON.stringify(localFormData));
            
            for (let i = 0; i < localFormData["evidence"].length; i++) {
                form.append("evidence", localFormData["evidence"][i]);
            }
            
            form.append("file", pdfBlob, "temp.pdf");

            axios.sendFilesContingencias(form)
                .then((response) => {
                    setLoading(false);
                    //setSubmitConfirm(!submitConfirm)
                    setModalError(false);
                    setModalContent("Documento enviado y firmado");
                    setOpen(true);
                })
                .catch((error) => {
                    setModalError(true);
                    setModalError("La creacion del documento a fallado");
                    setLoading(false);

                })
        setLoadingPdf(false);
        setSubmitConfirm(false);
      })
      .catch((error) => {
        console.error('Error generando el PDF:', error);
        setLoadingPdf(false);
        setLoading(false);
        setSubmitConfirm(false);
      });
  }, 0);

};

const getJornadas = (data) => {
    const jornadas = Object.keys(data)
      .filter((key) => key.startsWith('jornada_') && data[key])
      .map((key) => key.split('_')[1].toUpperCase()); 

    if (jornadas.length === 0) {
      return '-';
    }

    return jornadas.join(' - ');
  };
 
    function getEes() {
        if (autoCompleteInput !== "") {
            let rbd_temp = autoCompleteInput
            axios.getEes(rbd_temp, "1")
                .then((response) => {
                    let tempRep = []
                    setEesData(response.data);
                    setFormData({
                        ...formData,
                        nombre_colegio: response.data.nombre,
                        jornada: getJornadas(response.data),
                        id_zona: response.data.id_zona+""
                    })
                    setError(false);
                    setErrorMsg("")
                })
                .catch((error) => {
                    setFormData({
                        ...formData,
                        nombre_colegio: "Colegio no encontrado",
                        jornada: "",
                        id_zona: ""
                    })
                    setError(true);
                    setErrorMsg("Colegio no encontrado")
                })
        }
    }

      function getRBD() {
        if (autoCompleteInput !== "") {
            let rbd_temp = autoCompleteInput
            setRbdLoading(true)
            axios.getRBD(rbd_temp)
                .then((response) => {
                    setRBDList(response.data.rbd_list)
                    setRbdLoading(false);
                    setError(false);
                    setErrorMsg("");
                    getEes()
                })
                .catch((error) => {
                    setRbdLoading(true)
                    setError("Colegio no encontrado");
                })
        }
    }


      useEffect(() => {
        setFormData({ ...formData, rbd: autoCompleteInput ? autoCompleteInput : "" })
        getRBD()
        
    }, [autoCompleteInput]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

      useEffect(() => {
        getOperator()
        setDrawerHidding(false)
    }, [setPageTitle, setDrawerHidding]);

  return (
    <>
    <PageBody>
      <PageBgBlock pad="0" >
      { loading ? (<HourglassLoader/>) : (<><Box ref={contingenciasRef} sx={{ paddingLeft: "9rem", paddingRight: "9rem", paddingTop: "2rem"}}> 
        <Typography
          variant="h1"
          align="center"
          sx={{ marginBottom: "0.24rem" }}
        >
          LEVANTAMIENTO DE CONTINGENCIA
        </Typography>
        <Box 
          justifyContent="center"
          sx={{ maxWidth: "100%", margin: "0 auto", display: "flex" }}
        >
          <Typography
            variant="body"
            align="center"
            sx={{ marginBottom: "2rem" }}
          >
                  Complete el siguiente formulario para registrar una contingencia (Ticket Mesa de Ayuda).  Asegúrese de proporcionar todos los datos requeridos para facilitar el proceso de asistencia y resolución. Nuestro equipo revisará su solicitud y le brindará el soporte necesario a la brevedad.
          </Typography>
        </Box>

        <form onSubmit={save} id="contingencia">
          <Stack spacing={4} sx={{ marginBottom: "2rem" }}>
            <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              DATOS DEL EMISOR
            </Typography>

            <Grid container spacing={2}>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    required
                    name="rut"
                    label="Rut Operador Administrador"
                    size="small"
                    value={formData.rut_emisor}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Grid>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    required
                    name="nombre"
                    label="Nombre y Apellido"
                    size="small"
                    value={formData.name_emisor}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              DATOS DEL OPERADOR
            </Typography>

            <Grid container spacing={2}>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    required
                    name="id_operador"
                    label="ID Operador"
                    size="small"
                    value={formData.id_operador}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Grid>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    required
                    name="operator_name"
                    label="Nombre Operador"
                    size="small"
                    value={formData.operator_name}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Grid>
            </Grid>

                        <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              DATOS EES
            </Typography>

            <Grid container spacing={2}>
              <Grid size={6}>
              <Box ref={autoCompleteRef}>
                <FormControl sx={{ minWidth: "100%" }}>
                        <Autocomplete
                            key="rbd"
                            value={autoCompleteInput}
                            onChange={(event, newValue) => {
            setAutoCompleteInput(newValue ? newValue : "");
            setFormData({ ...formData, rbd: newValue ? newValue : "" });
        }}
                            inputValue={autoCompleteInput}
                            onInputChange={(event, newValue) => {
            setAutoCompleteInput(newValue ? newValue : "");
            setFormData({ ...formData, rbd: newValue ? newValue : "" });
        }}
                            disablePortal
                            loading={rbdLoading}
                            loadingText="Cargando..."
                            multiple={false}
                            options={RBDList}
                            //blurOnSelect
                            autoComplete
                            size='small'
                            //onBlur={getEes}
                            renderInput=
                            {
                                (params) => {
                                    return (
                                        <TextField required
                                            //onChange={(event) => handleAutoRBDchange(event, 1)}
                                            error={error} 
                                            helperText={errorMsg}
                                            {...params} label="RBD" />
                                    )
                                }
                            }
                        />
                    </FormControl>
                    </Box>
              </Grid>
              <Grid size={6}>
                            <FormControl fullWidth variant="outlined">
                                <TextField
                                    label="ID Ticket mesa de ayuda EES"
                                    size="small"
                                    value={formData.id_ticket_ayuda}
                                    sx={{ width: "100%" }}
                                    onChange={(newValue) =>
                                      setFormData({ ...formData, id_ticket_ayuda: newValue.target.value })
                                    }
                                />
                </FormControl>

                            </Grid>
             
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                                <TextField

                                    label="ID Zona"
                                    size="small"
                                    disabled
                                    value={formData.id_zona}
                                    sx={{ width: "100%" }}
                                />
                </FormControl>

                            </Grid>

                             <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    required
                    name="jornada"
                    label="Tipo de jornada"
                    size="small"
                    value={formData.jornada}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Grid>
                            
                            <Grid size={12} >
                            <FormControl fullWidth variant="outlined">
                                <TextField
                                    id="outlined"
                                    label="Nombre del Colegio"
                                    size="small"
                                    disabled
                                    value={formData.nombre_colegio}
                                    sx={{ width: "100%" }}
                                />
                </FormControl>
                            </Grid>
            </Grid>

            <Box ref={rutRef}>
            <InputRut error={errorRut} errorRut={handleErrorChange} checked={formData.checked} rut={formData.ees_temporal_manager} handleSwitch={handleSwitch} handleRut={(newValue) => setFormData({ ...formData, ["ees_temporal_manager"]: newValue})} ></InputRut>
            </Box>
            <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{}}
            >
              DATOS DE FALLA
            </Typography>

            <Grid container spacing={2}>
              <Grid size={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"es"}
                >
                  <DateTimePicker
                    sx={{ width: "100%" }}
                    label="Fecha y hora ocurrencia"
                    value={formData.fecha_hora_ocurrencia}
                    onChange={(newValue) =>
                      setFormData({ ...formData, fecha_hora_ocurrencia: newValue })
                    }
                    slotProps={{
                      textField: {
                        required: true,
                        size: "small",
                        helperText: errorMessages.fecha_hora_ocurrencia,
                        error: !!errorMessages.fecha_hora_ocurrencia,
                      },
                    }}
                    disableFuture
                  />
                </LocalizationProvider>
              </Grid>
              <Grid size={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"es"}
                >
                  <DateTimePicker
                    sx={{ width: "100%" }}
                    label="Fecha y hora solución"
                    value={formData.fecha_hora_solucion}
                    onChange={(newValue) =>
                      setFormData({ ...formData, fecha_hora_solucion: newValue })
                    }
                    minDate={formData.fecha_hora_ocurrencia}
                    slotProps={{
                      textField: {
                        required: true,
                        size: "small",
                        helperText: errorMessages.fecha_hora_solucion,
                        error: !!errorMessages.fecha_hora_solucion,
                      },
                    }}
                    disableFuture
                  />
                </LocalizationProvider>
              </Grid>
              
            </Grid>

            <Grid container spacing={2}>
              <Grid size={6}>
                <FormControl sx={{ width: "100%" }} size="small">
                  <InputLabel
                    id="severidad-label"
                    sx={{ backgroundColor: "#fff" }}
                  >
                    Severidad
                  </InputLabel>
                  <Select
                    labelId="severidad-label"
                    id="severidad"
                    value={formData.severidad}
                    label="Severidad"
                    size="small"
                    sx={{ width: "100%" }}
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        severidad: event.target.value,
                      })
                    }
                  >


                    <MenuItem value={"Crítica"}>Crítica</MenuItem>
                    <MenuItem value={"Mayor"}>Mayor</MenuItem>
                    <MenuItem value={"Menor"}>Menor</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid size={6}>
                <FormControl sx={{ width: "100%" }} size="small">
                  <InputLabel
                    id="codigo_tipo_evento-label"
                    sx={{ backgroundColor: "#fff" }}
                  >
                    Código tipo de evento
                  </InputLabel>
                  <Select
                    labelId="codigo_tipo_evento-label"
                    id="codigo_tipo_evento"
                    value={formData.codigo_tipo_evento}
                    label="Código tipo de evento"
                    size="small"
                    sx={{ width: "100%" }}
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        codigo_tipo_evento: event.target.value,
                      })
                    }
                  >
                    <MenuItem value={"1"}>1 - Falla física equipo de datos cliente</MenuItem>
                    <MenuItem value={"2"}>2 - Bloqueo equipo de datos cliente</MenuItem>
                    <MenuItem value={"3"}>3 - Corte enlace físico (Fibra óptica o cobre)</MenuItem>
                    <MenuItem value={"4"}>4 - Corte enlace microondas</MenuItem>
                    <MenuItem value={"5"}>5 - Caída plataforma satelital</MenuItem>
                    <MenuItem value={"6"}>6 - Caída enlace internacional</MenuItem>
                    <MenuItem value={"7"}>7 - Falla nodo de red</MenuItem>
                    <MenuItem value={"8"}>8 - Falla red de transporte</MenuItem>
                    <MenuItem value={"9"}>9 - Error de configuración firewall</MenuItem>
                    <MenuItem value={"10"}>10 - Error de configuración DNS</MenuItem>
                    <MenuItem value={"11"}>11 - Error de configuración otro nodo de servicio</MenuItem>
                    <MenuItem value={"12"}>12 - Falla de equipo firewall</MenuItem>
                    <MenuItem value={"13"}>13 - Falla de equipo DNS</MenuItem>
                    <MenuItem value={"14"}>14 - Falla de equipo otro nodo de servicio</MenuItem>
                    <MenuItem value={"15"}>15 - Corte de energía EES</MenuItem>
                    <MenuItem value={"16"}>16 - Corte suministro público energía</MenuItem>
                    <MenuItem value={"17"}>17 - Otro responsabilidad del operador</MenuItem>
                    <MenuItem value={"18"}>18 - Otro responsabilidad de terceros</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid size={6}>
                <FormControl sx={{ width: "100%" }} size="small">
                  <InputLabel
                    id="origen-label"
                    sx={{ backgroundColor: "#fff" }}
                  >
                    Origen de la falla
                  </InputLabel>
                  <Select
                    labelId="origen-label"
                    id="origen"
                    value={formData.origen}
                    label="Origen de la falla"
                    size="small"
                    sx={{ width: "100%" }}
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        origen: event.target.value,
                      })
                    }
                  >
                    <MenuItem value={"Falla interna al adjudicatario"}>Falla interna al adjudicatario</MenuItem>
                    <MenuItem value={"Falla externa al adjudicatario"}>
                      Falla externa al adjudicatario
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid size={6}>
                <FormControl sx={{ width: "100%" }} size="small">
                  <InputLabel
                    id="extension_falla-label"
                    sx={{ backgroundColor: "#fff" }}
                  >
                    Extensión de la falla
                  </InputLabel>
                  <Select
                    labelId="extension_falla-label"
                    id="extension_falla"
                    value={formData.extension_falla}
                    label="Extensión de la falla"
                    size="small"
                    sx={{ width: "100%" }}
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        extension_falla: event.target.value,
                      })
                    }
                  >
                    <MenuItem value={"Solo EES"}>Solo EES</MenuItem>
                    <MenuItem value={"Evento mayor"}>
                      Evento mayor
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{}}
            >
              DESCRIPCIÓN DE LA FALLA
            </Typography>
            { !loadingPdf ?
            (<>
            <FormControl sx={{ width: "100%", maxWidth: "100%" }}>
              <TextField
                required
                multiline
                minRows={4}
                maxRows={30}
                id="outlined-required"
                label="Texto de falla indicada por el Operador"
                placeholder="Texto de falla indicada por el Operador"
                size="small"
                value={formData.texto_falla_operador}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    texto_falla_operador: event.target.value,
                  })
                }
              />
            </FormControl>
            </>)
            :
            <>
              <InputFake value={formData.texto_falla_operador} ></InputFake>
            </>
            }

            


            <Box ref={evidenceRef}>
            <InputFile onFileSelect={setFile} fileType="pdf" placeholder="Subir evidencia (formato PDF)" />
            <Typography
              variant="small" align="left" sx={{ marginBottom: "18px", color:  evError ? 'red' : '#111' }}
            >
             {evError ? "Debe adjuntar evidencia de la instalación" : "Máximo 25mb"}
            </Typography>
            </Box>
          </Stack>

        </form>
          </Box>
          <Stack sx={{paddingLeft: "9rem", paddingRight: "9rem", paddingBottom: "3rem" }}>
          <Grid >
          {submitConfirm ?
                                <Grid display="flex" justifyContent="center" alignItems="center" size={12} sx={{paddingBottom: "3rem"}} >
                                    <Alert icon={false} variant="filled" severity="warning" display="flex" justifyContent="center" sx={{ display: "flex", justifyContent: "center", '& .MuiAlert-message': { textAlign: "center", width: "inherit" } }}>
                                        <Typography
                                            variant="body"
                                            color="inherit"
                                            sx={{ textAlign: "center" }}
                                        >
                                            Revise que todos los campos tienen la información correcta antes de enviar el levantamiento de Contingencia, al clickear “Firmar y enviar” el documento queda firmado por el operador.
                                        </Typography>
                                    </Alert>
                                </Grid>
                                :
                                ""
                            }
          </Grid>
          <PageButtonBlack form="contingencia" loading={loading}> Firmar y enviar</PageButtonBlack>
          </Stack>

          </>)
            }
      </PageBgBlock>
      <ModalStandard open={open} error={modalError} title={modalContent} btnText="Volver a listado" onClick={() => navigate("/contingencias_lista")} onClose={handleClose}></ModalStandard>
    </PageBody>
    { loadingPdf ?
            (
            <Box sx={{maxWidth: "100%", overflow: "hidden", position: "relative", height: 0}}>
            <Box>
            <ContingenciasHiddenPreview formData={formData} evidence={evidence}></ContingenciasHiddenPreview>
            </Box>
            </Box>) : null}
    </>
  );
}
