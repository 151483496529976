import React, { useState, useContext, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { LoadingButton } from '@mui/lab';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import GeneralContext from '../GeneralContext/GeneralContext';
import Grid from '@mui/material/Grid2';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

import { NumericFormat } from 'react-number-format';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { esES } from '@mui/x-date-pickers/locales';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import axios from "../../api/axios";
import Alert from '@mui/material/Alert';
import { Divider } from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import InputRut from "../GeneralComponents/InputRut";

import ModalStandard from "../GeneralComponents/ModalStandard";

import PageBody from "../GeneralComponents/PageBody";
import ParqueAdd from "./ParqueAdd";
import ParqueDelete from "./ParqueDelete";

import { useNavigate } from "react-router-dom";

let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);



export default function Parque() {

    const { setPageTitle, setDrawerHidding, userSesion } = useContext(GeneralContext);
    const [loading, setLoading] = useState(false);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [techTypes, setTechTypes] = useState([]);
    const [RBDList, setRBDList] = useState([""]);
    const [submitConfirm, setSubmitConfirm] = useState(false);
    const [rbdLoading, setRbdLoading] = React.useState(false);
    const [autoCompleteInput, setAutoCompleteInput] = React.useState("");
    const fusRef = useRef();
    const autoCompleteRef = useRef(null);
    const rutRef = useRef(null);
    const evidenceRef = useRef(null);   
    const [evError, setEvError] = useState(true);    
    const [error, setError] = useState("");
    const [errorRut, setErrorRut] = useState(false);

    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const navigate = useNavigate();
    const [modalError, setModalError] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const [loadingPdf, setLoadingPdf] = useState(false);




    useEffect(() => {
        setDrawerHidding(false)
        setPageLoaded(true)
    }, []);


    if (pageLoaded) {
        return (
           
    <PageBody>
        <ParqueAdd></ParqueAdd>
        <Box sx={{height: "2rem"}}></Box>
        <ParqueDelete></ParqueDelete>

    </PageBody>
           )


}
}