import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import GeneralContext from "../GeneralContext/GeneralContext";
import axios from "../../api/axios";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid2";
import { Box, Typography } from "@mui/material";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Divider from '@mui/material/Divider';

import OtherHousesOutlinedIcon from "@mui/icons-material/OtherHousesOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import SchoolIcon from '@mui/icons-material/School';

import PageBody from "../GeneralComponents/PageBody";
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import PageButtonWhite from "../GeneralComponents/PageButtonWhite";
import PageButtonSimple from "../GeneralComponents/PageButtonSimple";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import PageCoverImg from "../GeneralComponents/PageCoverImg";
import EmbeddedOperadorGrid from "./EmbeddedOperador";
import EmbeddedMineducGrid from "./EmbeddedMineduc";
import EmbeddedSubtelGrid from "./EmbeddedSubtel";

import EditIcon from "@mui/icons-material/Edit";

import { ReactComponent as DocSign } from "./doc_sign.svg";
import { ROL_MINEDUC_ADMIN, ROL_REP_EES, ROL_ADMIN_SUBTEL, ROL_VAL_SUBTEL, ROL_CON_MINEDUC, ROL_OP_ADMIN, ROL_OP_INS} from "../../Constants/Constants";


export default function Home() {
  const { includeRol, userSesion } = useContext(GeneralContext);

  const navigate = useNavigate();
  const current_date = new Date();
  const [eesData, setEesData] = useState(null);

  const options = { month: "long" };
  const month = current_date.toLocaleDateString("es-ES", options).toUpperCase();

const getJornadas = () => {
    const jornadas = Object.keys(eesData)
      .filter((key) => key.startsWith('jornada_') && eesData[key])
      .map((key) => key.split('_')[1].toUpperCase()); 

    if (jornadas.length === 0) {
      return '-';
    }

    return jornadas.join(' - ');
  };



  useEffect(() => {
    const fetchData = async () => {
      if (includeRol([ROL_REP_EES]) && !eesData) {
        axios
          .getEesByRut(userSesion.rut)
          .then((response) => {
            setEesData(response.data);
          })
          .catch((error) => {
          });
      }
    };
    fetchData();
  }, []);

  const renderGrid = () => {
  if (includeRol([ROL_ADMIN_SUBTEL, ROL_VAL_SUBTEL])) {
    return <EmbeddedSubtelGrid />;
  } else if (includeRol([ROL_CON_MINEDUC, ROL_MINEDUC_ADMIN])) {
    return <EmbeddedMineducGrid />;
  } else if (includeRol([ROL_OP_ADMIN])) {
    return <EmbeddedOperadorGrid />;
  }
  return null; 
};

  return (


    <PageBody>
      <Stack spacing={4}>
        { includeRol([ROL_OP_INS]) && (<Grid container spacing={2} sx={{ paddingBottom: "15px" }}>
          <Grid size={6}>
            <PageButtonBlack
              onClick={() => navigate("/fas")}
              icon={<EditIcon />}
              width="100%"
              pad="10px 18px"
            >
              Crear nuevo FAS
            </PageButtonBlack>
          </Grid>
          <Grid size={6}>
            <PageButtonWhite
              onClick={() => navigate("/fus")}
              icon={<EditIcon />}
              width="100%"
              pad="10px 18px"
            >
              Crear nuevo FUS
            </PageButtonWhite>
          </Grid>
        </Grid>
      )}
      
        { includeRol([ROL_REP_EES]) && (<Grid container spacing={2} sx={{ paddingBottom: "15px" }}>
          <Grid size={3}>
            <PageButtonBlack
              onClick={() => navigate("/fas_lista")}
              width="100%"
              pad="10px 18px"
            >
              Ver listado FAS
            </PageButtonBlack>
          </Grid>
          <Grid size={3}>
            <PageButtonBlack
              onClick={() => navigate("/fus_lista")}
              width="100%"
              pad="10px 18px"
            >
              Ver listado FUS
            </PageButtonBlack>
          </Grid>
          <Grid size={6}>
            <PageButtonWhite
              onClick={() => window.open("/service/grafana/d/be0kjiscwhzwga/reporte-supervision", "_blank")}
              width="100%"
              pad="10px 18px"
            >
              Monitoreo en línea
            </PageButtonWhite>
          </Grid>
        </Grid>
        )}

        { includeRol([ROL_ADMIN_SUBTEL, ROL_MINEDUC_ADMIN]) && (<Grid container spacing={2} sx={{ paddingBottom: "15px" }}>
          <Grid size={6}>
            <PageButtonBlack
              onClick={() => window.open("/service/grafana/d/be0kjiscwhzwga/reporte-supervision", "_blank")}
              width="100%"
              pad="10px 18px"
            >
              Monitoreo en línea
            </PageButtonBlack>
          </Grid>
          <Grid size={3}>
            <PageButtonWhite
              onClick={() => navigate("/fas_lista")}
              width="100%"
              pad="10px 18px"
            >
              Ver listado FAS
            </PageButtonWhite>
          </Grid>
          <Grid size={3}>
            <PageButtonWhite
              onClick={() => navigate("/fus_lista")}
              width="100%"
              pad="10px 18px"
            >
              Ver listado FUS
            </PageButtonWhite>
          </Grid>
        </Grid>
        )}


      {renderGrid()}

        { includeRol([ROL_REP_EES]) && eesData && (
          <Grid container spacing={2} alignItems="stretch">
            <Grid size={6}>
              <PageBgBlock pad="3rem 1rem" height="100%">
                <Box
                  sx={{
                    flexDirection: "column",
                    justifyContent: "center",
                    display: "flex",
                    height: "100%",
                  }}
                >
                  <Grid container spacing={2} sx={{ marginBottom: "3rem" }}>
                    <Grid size={4}>
                      <Avatar
                        sx={{
                          margin: "auto",
                          width: "10vw",
                          height: "10vw",
                          maxWidth: "130px",
                          maxHeight: "130px",
                          backgroundColor: "#FFECEF",
                          textAlign: "center",
                        }}
                      >
                        <Box>
                          <Typography variant="h3"> {eesData.rbd} </Typography>
                          <Typography variant="h4"> RBD </Typography>
                        </Box>
                      </Avatar>
                    </Grid>
                    <Grid size={4}>
                      <Avatar
                        sx={{
                          margin: "auto",
                          width: "10vw",
                          height: "10vw",
                          maxWidth: "130px",
                          maxHeight: "130px",
                          backgroundColor: "#FFECEF",
                          textAlign: "center",
                        }}
                      >
                        <Box>
                          <Typography variant="h3"> {getJornadas()} </Typography>
                          <Typography variant="h4"> Jornada </Typography>
                        </Box>
                      </Avatar>
                    </Grid>
                    <Grid size={4}>
                      <Avatar
                        sx={{
                          margin: "auto",
                          width: "10vw",
                          height: "10vw",
                          maxWidth: "130px",
                          maxHeight: "130px",
                          backgroundColor: "#FFECEF",
                          textAlign: "center",
                        }}
                      >
                        <Box>
                          <Typography variant="h3"> - </Typography>
                          <Typography variant="h4"> Matricula </Typography>
                        </Box>
                      </Avatar>
                    </Grid>
                  </Grid>

                  <Typography variant="h2" align="center"> 
                    {eesData.nombre_colegio}
                  </Typography>
                  <Typography variant="h4" align="center"> 
                    {eesData.tipo
                      ? eesData.tipo
                      : "Desconocido"}
                  </Typography>
                </Box>
              </PageBgBlock>
            </Grid>
            <Grid size={6}>
              <PageBgBlock pad="36px 30px" height="100%">
                <Box
                  sx={{
                    flexDirection: "column",
                    justifyContent: "center",
                    display: "flex",
                    height: "100%",
                  }}
                >
                  <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                    <ListItem disablePadding>
                      <ListItemAvatar>
                        <Avatar
                          sx={{ color: "#111", backgroundColor: "transparent" }}
                        >
                          <SchoolIcon  />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText sx={{'& .MuiTypography-root': {lineHeight: 1,}, }}
                        primary={<Typography variant="h5">Sostenedor</Typography>}
                        secondary={
                          <Typography variant="small"> { eesData.sostenedor ? eesData.sostenedor : "Desconocido" }</Typography>
                        }
                      />
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemAvatar>
                        <Avatar
                          sx={{ color: "#111", backgroundColor: "transparent" }}
                        >
                          <OtherHousesOutlinedIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText sx={{'& .MuiTypography-root': {lineHeight: 1,}, }}
                        primary={<Typography variant="h5">Dirección</Typography>}
                        secondary={
                          <Typography variant="small"> { eesData.direccion ? eesData.direccion : "Desconocido" }</Typography>
                        }
                      />
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemAvatar>
                        <Avatar
                          sx={{ color: "#111", backgroundColor: "transparent" }}
                        >
                          <PhoneOutlinedIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText sx={{'& .MuiTypography-root': {lineHeight: 1,}, }}
                        primary={<Typography variant="h5">Teléfono</Typography>}
                        secondary={
                          <Typography variant="small"> {eesData.telefono ? eesData.telefono : "Desconocido"}</Typography>
                        }
                      />
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemAvatar>
                        <Avatar
                          sx={{ color: "#111", backgroundColor: "transparent" }}
                        >
                          <MailOutlinedIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText sx={{'& .MuiTypography-root': {lineHeight: 1,}, }}
                        primary={<Typography variant="h5">E-mail</Typography>}
                        secondary={
                          <Typography variant="small"> {eesData.email ? eesData.email : "Desconocido"}</Typography>
                        }
                      />
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemAvatar>
                        <Avatar
                          sx={{ color: "#111", backgroundColor: "transparent" }}
                        >
                          <FmdGoodOutlinedIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText sx={{'& .MuiTypography-root': {lineHeight: 1,}, }}
                        primary={<Typography variant="h5">Ubicación</Typography>}
                        secondary={<Typography variant="small">{eesData.latitud + ", " + eesData.longitud} </Typography>}
                      />
                    </ListItem>
                  </List>
                </Box>
              </PageBgBlock>
            </Grid>
          </Grid>
        )}

        <PageBgBlock pad="0 0 0 1.5rem">
          <Grid container spacing={0}>
            <Grid size={8}>
              <PageBgBlock pad="2rem ">
              <Typography variant="h1" align="left" sx={{ marginBottom: "1rem"}}>¿Qué es SAGEC?</Typography>
                 <Box justifyContent="center" sx={{maxWidth: "100%", margin: '0 auto'}}>
                  <Typography variant="body1"  align="left" sx={{ marginBottom: "1.5rem"}}>
                  SAGEC es una plataforma que facilita el acuerdo, la medición y
                  reportes de conexión a internet entre los establecimientos
                  educacionales subvencionados (EES) y los operadores de
                  internet de todo el pais.
                  </Typography>
                </Box>
              </PageBgBlock>
            </Grid>
            <Grid size={4}>
              <PageCoverImg img="url(/assets/img-autenticacion.jpg)" />
            </Grid>
          </Grid>
        </PageBgBlock>

        <Grid container spacing={2} alignItems="stretch">
          <Grid size={8}>
            <PageBgBlock height="100%" pad="1.5rem 3.5rem">
              <Box
                sx={{
                  flexDirection: "column",
                  justifyContent: "center",
                  display: "flex",
                  height: "100%",
                }}
              >
                <Box  align="center" sx={{marginBottom: "20px" }}>
                  <DocSign />
                </Box>
                <Typography variant="h1" align="left" sx={{ marginBottom: "1.2rem"}}> ¿Le gustaría firmar los formularios con su propia firma manuscrita?</Typography>
                
                <Box justifyContent="center" sx={{maxWidth: "100%", margin: '0 auto'}}>
                  <Typography variant="body1"  align="left" sx={{ marginBottom: "1.5rem"}}>
                        
                  En SAGEC puedes firmar formularios con tu propia firma caligráfica. Solo ingresa a "Editar perfil" en tu cuenta y sube una imagen de tu firma manuscrita siguiendo los parámetros establecidos.
                  </Typography>
                </Box>

            

                <PageButtonSimple onClick={() => navigate("/configuracion")}>
                  Ir a cargar imagen de firma
                </PageButtonSimple>
              </Box>
            </PageBgBlock>
          </Grid>
          <Grid size={4}>
            <PageBgBlock pad="30px 0 50px" height="100%">

              <Typography variant="h5" align="center" sx={{ fontFamily: "Roboto", fontWeight: "300"}}>¿Qué día es hoy?</Typography>

              <Divider sx={{ margin: "1.5rem 0", borderColor: "#F3F3F3"}}/>
              <Box
                sx={{
                  flexDirection: "column",
                  justifyContent: "center",
                  display: "flex",
                  height: "100%",
                }}
              >

                <Typography variant="h3" align="center" sx={{ fontFamily: "Roboto", marginBottom: "-1rem"}}>{month}</Typography>
                <Typography variant="h1" align="center" sx={{ fontFamily: "Roboto", marginBottom: "-1rem", fontSize: '8.75rem', color: '#0F69C4'}}>{current_date.getDate()}</Typography>
                

                <Typography variant="h3" align="center" sx={{fontFamily: "Roboto"}}>Año {current_date.getFullYear()}</Typography>

              </Box>
            </PageBgBlock>
          </Grid>
        </Grid>
      </Stack>
    </PageBody>
  );
}
