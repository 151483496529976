import React, { useRef, useState, useEffect } from 'react';
import { TextField, Button, InputAdornment, Chip, Stack, Avatar } from '@mui/material';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Grid from '@mui/material/Grid2';

const textFieldStyles = {
  width: '100%',
  '& .MuiOutlinedInput-input': { 
    padding: '8px 10px 9px 0px'
  }, 
  '& .MuiOutlinedInput-root': {
    padding: '0',
  },
};

const buttonStyles = {
  backgroundColor: 'rgba(25, 118, 210, 0.04)',
  color: '#003B8D',
  border: 'none',
  textTransform: 'none',
  borderRadius: '4px 4px',
  '&:hover': {
    backgroundColor: 'rgba(25, 118, 210, 0.04)',
  },
  fontWeight: '400',
  fontSize: '16px',
  boxShadow: 'none',
};

const chipStyles = {
  borderRadius: 0,
  border: '2px solid #ABABB5',
  color: '#111', 
};

const FileInput = ({ onFileSelect, fileType = 'image', placeholder="Subir imagen de firma", initialFile=null }) => {
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState('');
  const [filePreview, setFilePreview] = useState('');

  const OpenFile = () => {
    fileInputRef.current.click();
  };

  const handleDeleteFile = () => {
    setFileName('');
    setFilePreview('');
    fileInputRef.current.value = '';
    onFileSelect('');
  };



    useEffect(() => {
      if (initialFile && initialFile.archivo){
        fileHandler(initialFile.archivo);
      }
    }, [initialFile]);

  const fileHandler = (archivo) => {
      const isImage = archivo.type.startsWith('image/');
      const isPDF = archivo.type === 'application/pdf';

      if ((fileType === 'image' && isImage) || 
          (fileType === 'pdf' && isPDF) || 
          (fileType === 'both' && (isImage || isPDF))) {
        
        // Validación de tamaño y previsualización
        if (isImage) {
          const img = new Image();
          img.src = URL.createObjectURL(archivo);
          img.onload = () => {
            if (img.width > 256 || img.height > 256) {
              alert('La imagen no puede superar 256x256 píxeles.');
              resetFileInput();
            } else {
              setFileName(archivo.name);
              onFileSelect(archivo);

              const reader = new FileReader();
              reader.onload = (e) => {
                setFilePreview(e.target.result);
              };
              reader.readAsDataURL(archivo);
            }
          };
        } else if (isPDF) {
          if (archivo.size > 25 * 1024 * 1024) { 
            alert('El archivo PDF no puede superar los 25 MB.');
            resetFileInput();
          } else {
            setFileName(archivo.name);
            setFilePreview(null); 
            onFileSelect(archivo);
          }
        }
      } else {
        alert('Tipo de archivo no permitido.');
        resetFileInput();
      }
  }

  const fileChange = (event) => {
    const archivo = event.target.files[0];
    if (archivo) {
      fileHandler(archivo);
    }
  };

  const resetFileInput = () => {
    setFileName('');
    setFilePreview('');
    fileInputRef.current.value = '';
    onFileSelect(null);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid size={9}>
          <TextField
            variant="outlined"
            placeholder={placeholder}
            value={fileName}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{marginLeft: "10px"}}>
                  <ImageSearchIcon sx={{color: 'black'}}/>
                </InputAdornment>
              ),
              readOnly: true,
            }}
            onClick={OpenFile} 
            readOnly
            sx={textFieldStyles}
          />
        </Grid>
        <Grid size={3}>
          <Button fullWidth variant="text" color="primary" startIcon={<FileUploadIcon />} sx={buttonStyles} onClick={OpenFile}>
            Añadir archivo
          </Button>
        </Grid>
      </Grid>

      <input
        type="file"
        ref={fileInputRef}
        accept={
          fileType === 'image' ? 'image/jpeg, image/png' :
          fileType === 'pdf' ? 'application/pdf' : 'image/jpeg, image/png, application/pdf'
        }
        style={{ display: 'none' }}
        onChange={fileChange}
      />
      {fileName && (
        <Stack direction="row" spacing={1} mt={2}>
          <Chip
            avatar={
              filePreview ? (
                <Avatar src={filePreview} alt={fileName} sx={{ borderRadius: 0 }} />
              ) : (
                <Avatar>
                  <FileUploadIcon />
                </Avatar>
              )
            }
            label={fileName}
            onDelete={handleDeleteFile}
            sx={chipStyles}
          />
        </Stack>
      )}
    </>
  );
};

export default FileInput;
