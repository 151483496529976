import React from "react";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PageBody from "../GeneralComponents/PageBody";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import InputFake from "../GeneralComponents/InputFake";
import InputRut from "../GeneralComponents/InputRut";
import InputFile from "../GeneralComponents/InputFile";
import SignatureFooter from "../GeneralComponents/SignatureFooter";

let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);

export default function ContingenciasHiddenPreview({formData, evidence}) {
  return (
    <>
    <PageBody>
      <PageBgBlock pad="2rem 9rem" >
      <Box  > 
        <Typography
          variant="h1"
          align="center"
          sx={{ marginBottom: "0.24rem" }}
        >
          LEVANTAMIENTO DE CONTINGENCIA
        </Typography>
        <Box 
          justifyContent="center"
          sx={{ maxWidth: "100%", margin: "0 auto", display: "flex" }}
        >
          <Typography
            variant="body"
            align="center"
            sx={{ marginBottom: "2rem" }}
          >
                  Complete el siguiente formulario para registrar una contingencia (Ticket Mesa de Ayuda).  Asegúrese de proporcionar todos los datos requeridos para facilitar el proceso de asistencia y resolución. Nuestro equipo revisará su solicitud y le brindará el soporte necesario a la brevedad.
          </Typography>
        </Box>

        <form id="contingencia">
          <Stack spacing={4} sx={{ marginBottom: "2rem" }}>
            <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              DATOS DEL EMISOR
            </Typography>

            <Grid container spacing={2}>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    required
                    name="rut"
                    label="Rut Operador Administrador"
                    size="small"
                    value={formData.rut_emisor}
                  />
                </FormControl>
              </Grid>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    required
                    name="nombre"
                    label="Nombre y Apellido"
                    size="small"
                    value={formData.name_emisor}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              DATOS DEL OPERADOR
            </Typography>

            <Grid container spacing={2}>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    required
                    name="id_operador"
                    label="ID Operador"
                    size="small"
                    value={formData.id_operador}
                  />
                </FormControl>
              </Grid>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    required
                    name="operator_name"
                    label="Nombre Operador"
                    size="small"
                    value={formData.operator_name}
                  />
                </FormControl>
              </Grid>
            </Grid>

                        <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              DATOS EES
            </Typography>

            <Grid container spacing={2}>
              <Grid size={6}>
               <FormControl sx={{ minWidth: "100%" }}>
                    <Grid container spacing={2}>
                      <Grid size={12}>
                        <TextField
                          disabled
                          id="outlined-required"
                          label="RBD"
                          size="small"
                          sx={{ width: "100%" }}
                          value={formData.rbd}
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
              </Grid>
              <Grid size={6}>
                            <FormControl fullWidth variant="outlined">
                                <TextField
                                    required
                                    label="ID Ticket mesa de ayuda EES"
                                    size="small"
                                    value={formData.id_ticket_ayuda}
                                    sx={{ width: "100%" }}
                                />
                </FormControl>

                            </Grid>
             
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                                <TextField

                                    label="ID Zona"
                                    size="small"
                                    disabled
                                    value={formData.id_zona}
                                    sx={{ width: "100%" }}
                                />
                </FormControl>

                            </Grid>

                             <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    required
                    name="jornada"
                    label="Tipo de jornada"
                    size="small"
                    value={formData.jornada}
                  />
                </FormControl>
              </Grid>
                            
                            <Grid size={12} >
                            <FormControl fullWidth variant="outlined">
                                <TextField
                                    id="outlined"
                                    label="Nombre del Colegio"
                                    size="small"
                                    disabled
                                    value={formData.nombre_colegio}
                                    sx={{ width: "100%" }}
                                />
                </FormControl>
                            </Grid>
            </Grid>

            <Box>
            <InputRut errorRut={() => {}} handleRut={() => {}} checked={formData.checked} rut={formData.ees_temporal_manager}></InputRut>
            </Box>
            <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{}}
            >
              DATOS DE FALLA
            </Typography>

            <Grid container spacing={2}>
              <Grid size={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"es"}
                >
                  <DateTimePicker
                    sx={{ width: "100%" }}
                    label="Fecha y hora ocurrencia"
                    value={formData.fecha_hora_ocurrencia}
                    disableFuture
                  />
                </LocalizationProvider>
              </Grid>
              <Grid size={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"es"}
                >
                  <DateTimePicker
                    sx={{ width: "100%" }}
                    label="Fecha y hora solución"
                    value={formData.fecha_hora_solucion}
                  />
                </LocalizationProvider>
              </Grid>
              
            </Grid>

            <Grid container spacing={2}>
              <Grid size={6}>
                <FormControl sx={{ width: "100%" }} size="small">
                  <InputLabel
                    id="severidad-label"
                    sx={{ backgroundColor: "#fff" }}
                  >
                    Severidad
                  </InputLabel>
                  <Select
                    required
                    labelId="severidad-label"
                    id="severidad"
                    value={formData.severidad}
                    label="Severidad"
                    size="small"
                    sx={{ width: "100%" }}
                  >


                    <MenuItem value={"Crítica"}>Crítica</MenuItem>
                    <MenuItem value={"Mayor"}>Mayor</MenuItem>
                    <MenuItem value={"Menor"}>Menor</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid size={6}>
                <FormControl sx={{ width: "100%" }} size="small">
                  <InputLabel
                    id="codigo_tipo_evento-label"
                    sx={{ backgroundColor: "#fff" }}
                  >
                    Código tipo de evento
                  </InputLabel>
                  <Select
                    required
                    labelId="codigo_tipo_evento-label"
                    id="codigo_tipo_evento"
                    value={formData.codigo_tipo_evento}
                    label="Código tipo de evento"
                    size="small"
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value={"1"}>1 - Falla física equipo de datos cliente</MenuItem>
                    <MenuItem value={"2"}>2 - Bloqueo equipo de datos cliente</MenuItem>
                    <MenuItem value={"3"}>3 - Corte enlace físico (Fibra óptica o cobre)</MenuItem>
                    <MenuItem value={"4"}>4 - Corte enlace microondas</MenuItem>
                    <MenuItem value={"5"}>5 - Caída plataforma satelital</MenuItem>
                    <MenuItem value={"6"}>6 - Caída enlace internacional</MenuItem>
                    <MenuItem value={"7"}>7 - Falla nodo de red</MenuItem>
                    <MenuItem value={"8"}>8 - Falla red de transporte</MenuItem>
                    <MenuItem value={"9"}>9 - Error de configuración firewall</MenuItem>
                    <MenuItem value={"10"}>10 - Error de configuración DNS</MenuItem>
                    <MenuItem value={"11"}>11 - Error de configuración otro nodo de servicio</MenuItem>
                    <MenuItem value={"12"}>12 - Falla de equipo firewall</MenuItem>
                    <MenuItem value={"13"}>13 - Falla de equipo DNS</MenuItem>
                    <MenuItem value={"14"}>14 - Falla de equipo otro nodo de servicio</MenuItem>
                    <MenuItem value={"15"}>15 - Corte de energía EES</MenuItem>
                    <MenuItem value={"16"}>16 - Corte suministro público energía</MenuItem>
                    <MenuItem value={"17"}>17 - Otro responsabilidad del operador</MenuItem>
                    <MenuItem value={"18"}>18 - Otro responsabilidad de terceros</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid size={6}>
                <FormControl sx={{ width: "100%" }} size="small">
                  <InputLabel
                    id="origen-label"
                    sx={{ backgroundColor: "#fff" }}
                  >
                    Origen de la falla
                  </InputLabel>
                  <Select
                    required
                    labelId="origen-label"
                    id="origen"
                    value={formData.origen}
                    label="Origen de la falla"
                    size="small"
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value={"Falla interna al adjudicatario"}>Falla interna al adjudicatario</MenuItem>
                    <MenuItem value={"Falla externa al adjudicatario"}>
                      Falla externa al adjudicatario
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid size={6}>
                <FormControl sx={{ width: "100%" }} size="small">
                  <InputLabel
                    id="extension_falla-label"
                    sx={{ backgroundColor: "#fff" }}
                  >
                    Extensión de la falla
                  </InputLabel>
                  <Select
                    required
                    labelId="extension_falla-label"
                    id="extension_falla"
                    value={formData.extension_falla}
                    label="Extensión de la falla"
                    size="small"
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value={"Solo EES"}>Solo EES</MenuItem>
                    <MenuItem value={"Evento mayor"}>
                      Evento mayor
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{}}
            >
              DESCRIPCIÓN DE LA FALLA
            </Typography>

              <InputFake value={formData.texto_falla_operador} ></InputFake>


            


           
            <Box >
            <InputFile onFileSelect={() => {}} initialFile={evidence} fileType="pdf" placeholder="Subir evidencia (formato PDF)" />
            <Typography
              variant="small" align="left"
              sx={{ marginBottom: "18px", color:'#111' }}
            >
             Máximo 25mb 
            </Typography>
            </Box>
          </Stack>

        </form>
          </Box>
      </PageBgBlock>
      <SignatureFooter></SignatureFooter>
      </PageBody>

        

            </>
  );
}
