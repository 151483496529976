import React, { useState, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import GeneralContext from "../GeneralContext/GeneralContext";
import Grid from "@mui/material/Grid2";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import "dayjs/locale/es";

import Alert from '@mui/material/Alert';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { esES } from "@mui/x-date-pickers/locales";
import axios from "../../api/axios";
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import PageButtonWhite from "../GeneralComponents/PageButtonWhite";

import PageBody from "../GeneralComponents/PageBody";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import { CircularProgress } from "@mui/material";

import ModalStandard from "../GeneralComponents/ModalStandard";
import BreadcrumbStandard from "../GeneralComponents/BreadcrumbStandard";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InputRut from "../GeneralComponents/InputRut";
import {  Button, InputAdornment, } from '@mui/material';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import FileDownload from '@mui/icons-material/FileDownload';



import {NO_EES, NO_EES_TEMP, NO_SUBTEL, NO_MINEDUC, ROL_REP_EES, ROL_REP_EES_TEMP, ROL_ADMIN_SUBTEL, ROL_VAL_SUBTEL, ROL_MINEDUC_ADMIN} from "../../Constants/Constants";

const buttonStyles = {
  backgroundColor: 'rgba(25, 118, 210, 0.04)',
  color: '#003B8D',
  border: 'none',
  textTransform: 'none',
  borderRadius: '4px 4px',
  '&:hover': {
    backgroundColor: 'rgba(25, 118, 210, 0.04)',
  },
  fontWeight: '400',
  fontSize: '16px',
  boxShadow: 'none',
};

let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);

export default function ContingenciasFirma() {
  const { pageTitle, setPageTitle, setDrawerHidding, userSesion, includeRol } =  useContext(GeneralContext);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [documentVisible, setDocumentVisible] = useState(true);

    const [submitConfirm, setSubmitConfirm] = useState(false);
  const [canSign, setCanSign] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const [modalError, setModalError] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const { id } = useParams();
  const links = [
    { name: 'Inicio', path: '/home' },
    { name: 'Listado Contingencias', path: '/contingencias_lista' },
    { name: 'Firma Contingencia', path: '/contingencias_firma/'+id },
  ];


  const [formData, setFormData] = useState({
    name_emisor: userSesion.nombre,
    rut_emisor: userSesion.rut,
    nombre_colegio: "",
    id_operador: "",
    id_zona: "",
    id_ticket_ayuda: "",
    operator_name: "", 
    checked: false,
    ees_temporal_manager: "",
    fecha_hora_ocurrencia: dayjs(),
    fecha_hora_solucion: dayjs(),
    fecha_hora_recurso: dayjs(),
    fecha_hora_ocurrencia: dayjs(),
    codigo_tipo_evento: "",
    origen: "",
    extension_falla: "",
    severidad: "",
    rbd: "",
    jornada: "",
    texto_falla_operador: "",
    evidence: "",
  });


    const fetchData = async (query) => {
    setLoading(true);
    try {
    const response = await axios.getContingencia(id);
    setFormData({
      ...response.data.datos,
      fecha_hora_ocurrencia: dayjs(response.data.datos['fecha_hora_ocurrencia'] || ""),
      fecha_hora_solucion: dayjs(response.data.datos['fecha_hora_solucion'] || ""),
      estado: response.data.estado,
    });
  } catch (error) {
    console.log(error);
    console.error("Error al obtener los datos:", error);
    setDocumentVisible(false);
  } finally {
    setLoading(false);
  }
  };

  function singDoc() {
    //event.preventDefault();
        if (!submitConfirm) {
        setSubmitConfirm(!submitConfirm)
        return;
    }

    const form = new FormData();
    const localFormData = {
        ...formData,
        fecha_hora_ocurrencia: formData["fecha_hora_ocurrencia"].locale('es').format('YYYY/MM/DD HH:MM:ss').toString(),
        fecha_hora_solucion: formData["fecha_hora_solucion"].locale('es').format('YYYY/MM/DD HH:MM:ss').toString(),
    }

    form.append("data", JSON.stringify(localFormData));
    setLoadingButton(true);
        axios
      .signDoc("contingencias",id, form)
      .then((response) => {
          setModalError(false);
          setModalContent("Documento enviado y firmado");
          setOpen(true);
      })
      .catch((error) => {
          setModalError(true);
          setModalError("La firma del documento a fallado");
      })
      .finally(() => {
       setLoadingButton(false);
        setSubmitConfirm(false);
      });

  }

    function rejectDoc() {
    //event.preventDefault();
    if (!submitConfirm) {
        setSubmitConfirm(!submitConfirm)
        return;
    }
    setLoadingButton(true);
    const form = new FormData();
    const localFormData = {
        ...formData,
        fecha_hora_ocurrencia: formData["fecha_hora_ocurrencia"].locale('es').format('YYYY/MM/DD HH:MM:ss').toString(),
        fecha_hora_solucion: formData["fecha_hora_solucion"].locale('es').format('YYYY/MM/DD HH:MM:ss').toString(),
    }

    form.append("data", JSON.stringify(localFormData));
    axios
      .rejectDoc("contingencias",id, form)
      .then((response) => {
        navigate("/contingencias_lista");
      })
      .catch((error) => {})
      .finally(() => {
       setLoadingButton(false);
        setSubmitConfirm(false);
      });
  }



  useEffect(() => {
    setPageTitle("Firma de Contingencia");
    fetchData();
    setDrawerHidding(false);
    setPageLoaded(true);
  }, []);


   useEffect(() => {   
    if (includeRol([ROL_REP_EES,ROL_REP_EES_TEMP]) && (formData.estado === NO_EES || formData.estado === NO_EES_TEMP)  ){
      setCanSign(true);
    }else if (includeRol([ROL_ADMIN_SUBTEL, ROL_VAL_SUBTEL]) && formData.estado === NO_SUBTEL){
      setCanSign(true);
    }else if(includeRol([ROL_MINEDUC_ADMIN]) && formData.estado === NO_MINEDUC){
      setCanSign(true);
    }
  }, [userSesion, formData]);

  if (pageLoaded) {
    return (
        <>
          <PageBody>
          <BreadcrumbStandard links={links} />
        {loading ? (
        <PageBgBlock pad="2rem 9rem">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
                width: "100%",
                backgroundColor: "white",
              }}
            >
              <CircularProgress />
              <Typography variant="h6" sx={{ marginTop: 2 }}>
                Cargando...
              </Typography>
            </Box>
        </PageBgBlock>

        ) : (
      documentVisible && canSign ? 


      <>
      <PageBgBlock pad="0" >
      <Box sx={{ paddingLeft: "9rem", paddingRight: "9rem", paddingTop: "2rem"}}> 
        <Typography
          variant="h1"
          align="center"
          sx={{ marginBottom: "0.24rem" }}
        >
          LEVANTAMIENTO DE CONTINGENCIA
        </Typography>
                      <Box justifyContent="center" sx={{display: 'flex', margin: '0.2rem!important'}}>
                    <Typography
                        variant="small"
                        color="inherit"
                        sx={{ textAlign: "center", justifyContent: "center", paddingY: "0", marginTop: '0'}}
                    >
                         N° folio: <Typography variant="small" sx={{paddingLeft:'1rem', marginTop: '0'}}>{String(id || '').padStart(8, '0')}</Typography>
                    </Typography>
                    </Box>
        <Box 
          justifyContent="center"
          sx={{ maxWidth: "100%", margin: "0 auto", display: "flex" }}
        >
          <Typography
            variant="body"
            align="center"
            sx={{ marginBottom: "2rem" }}
          >
                  Complete el siguiente formulario para registrar una contingencia (Ticket Mesa de Ayuda).  Asegúrese de proporcionar todos los datos requeridos para facilitar el proceso de asistencia y resolución. Nuestro equipo revisará su solicitud y le brindará el soporte necesario a la brevedad.
          </Typography>
        </Box>

        <form  id="contingencia" >
          <Stack spacing={4} sx={{ marginBottom: "2rem" }}>
            <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              DATOS DEL EMISOR
            </Typography>

            <Grid container spacing={2}>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    required
                    name="rut_emisor"
                    label="Rut Operador Administrador"
                    size="small"
                    value={formData.rut_emisor}
                  />
                </FormControl>
              </Grid>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    required
                    name="nombre_emisor"
                    label="Nombre y Apellido"
                    size="small"
                    value={formData.name_emisor}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              DATOS DEL OPERADOR
            </Typography>

            <Grid container spacing={2}>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    required
                    name="id_operador"
                    label="ID Operador"
                    size="small"
                    value={formData.id_operador}
                  />
                </FormControl>
              </Grid>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    required
                    name="operator_name"
                    label="Nombre Operador"
                    size="small"
                    value={formData.operator_name}
                  />
                </FormControl>
              </Grid>
            </Grid>

                        <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              DATOS EES
            </Typography>

            <Grid container spacing={2}>
              <Grid size={6}>
              <Box >
                <FormControl fullWidth variant="outlined">
                                <TextField
                                    required
                                    disabled
                                    label="RBD"
                                    size="small"
                                    value={formData.rbd}
                                    sx={{ width: "100%" }}
                                />
                </FormControl>
                    </Box>
              </Grid>
              <Grid size={6}>
                            <FormControl fullWidth variant="outlined">
                                <TextField
                                    required
                                    disabled
                                    label="ID Ticket mesa de ayuda EES"
                                    size="small"
                                    value={formData.id_ticket_ayuda}
                                    sx={{ width: "100%" }}
                                />
                </FormControl>

                            </Grid>
             
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                                <TextField

                                    label="ID Zona"
                                    size="small"
                                    disabled
                                    value={formData.id_zona}
                                    sx={{ width: "100%" }}
                                />
                </FormControl>

                            </Grid>

                             <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    required
                    name="jornada"
                    label="Tipo de jornada"
                    size="small"
                    value={formData.jornada}
                  />
                </FormControl>
              </Grid>
                            
                            <Grid size={12} >
                            <FormControl fullWidth variant="outlined">
                                <TextField
                                    id="outlined"
                                    label="Nombre del Colegio"
                                    size="small"
                                    disabled
                                    value={formData.nombre_colegio}
                                    sx={{ width: "100%" }}
                                />
                </FormControl>
                            </Grid>
            </Grid>

            <Box>
            <InputRut errorRut={() => {}} handleRut={() => {}} checked={formData.checked} rut={formData.ees_temporal_manager}></InputRut>
            </Box>
            <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{}}
            >
              DATOS DE FALLA
            </Typography>

            <Grid container spacing={2}>
              
              <Grid size={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"es"}
                >
                  <DateTimePicker
                  disabled
                    sx={{ width: "100%" }}
                    label="Fecha y hora ocurrencia"
                    value={formData.fecha_hora_ocurrencia}
                    slotProps={{
                      textField: {
                        size: "small",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              

              <Grid size={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"es"}
                >
                  <DateTimePicker
                    sx={{ width: "100%" }}
                    label="Fecha y hora solución"
                    value={formData.fecha_hora_solucion}
                    disabled
                    slotProps={{
                      textField: {
                        size: "small",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              
            </Grid>

            <Grid container spacing={2}>
              <Grid size={6}>
                <FormControl sx={{ width: "100%" }} size="small">
                  <InputLabel
                    id="severidad-label"
                    sx={{ backgroundColor: "#fff" }}
                  >
                    Severidad
                  </InputLabel>
                  <Select
                    required
                    disabled
                    labelId="severidad-label"
                    id="severidad"
                    value={formData.severidad}
                    label="Severidad"
                    size="small"
                    sx={{ width: "100%" }}
                  >


                    <MenuItem value={"Crítica"}>Critica</MenuItem>
                    <MenuItem value={"Mayor"}>Mayor</MenuItem>
                    <MenuItem value={"Menor"}>Menor</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid size={6}>
                <FormControl sx={{ width: "100%" }} size="small">
                  <InputLabel
                    id="codigo_tipo_evento-label"
                    sx={{ backgroundColor: "#fff" }}
                  >
                    Código tipo de evento
                  </InputLabel>
                  <Select
                    required
                    disabled
                    labelId="codigo_tipo_evento-label"
                    id="codigo_tipo_evento"
                    value={formData.codigo_tipo_evento}
                    label="Código tipo de evento"
                    size="small"
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value={"1"}>1 - Falla física equipo de datos cliente</MenuItem>
                    <MenuItem value={"2"}>2 - Bloqueo equipo de datos cliente</MenuItem>
                    <MenuItem value={"3"}>3 - Corte enlace físico (Fibra óptica o cobre)</MenuItem>
                    <MenuItem value={"4"}>4 - Corte enlace microondas</MenuItem>
                    <MenuItem value={"5"}>5 - Caída plataforma satelital</MenuItem>
                    <MenuItem value={"6"}>6 - Caída enlace internacional</MenuItem>
                    <MenuItem value={"7"}>7 - Falla nodo de red</MenuItem>
                    <MenuItem value={"8"}>8 - Falla red de transporte</MenuItem>
                    <MenuItem value={"9"}>9 - Error de configuración firewall</MenuItem>
                    <MenuItem value={"10"}>10 - Error de configuración DNS</MenuItem>
                    <MenuItem value={"11"}>11 - Error de configuración otro nodo de servicio</MenuItem>
                    <MenuItem value={"12"}>12 - Falla de equipo firewall</MenuItem>
                    <MenuItem value={"13"}>13 - Falla de equipo DNS</MenuItem>
                    <MenuItem value={"14"}>14 - Falla de equipo otro nodo de servicio</MenuItem>
                    <MenuItem value={"15"}>15 - Corte de energía EES</MenuItem>
                    <MenuItem value={"16"}>16 - Corte suministro público energía</MenuItem>
                    <MenuItem value={"17"}>17 - Otro responsabilidad del operador</MenuItem>
                    <MenuItem value={"18"}>18 - Otro responsabilidad de terceros</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid size={6}>
                <FormControl sx={{ width: "100%" }} size="small">
                  <InputLabel
                    id="origen-label"
                    sx={{ backgroundColor: "#fff" }}
                  >
                    Origen de la falla
                  </InputLabel>
                  <Select
                    required
                    disabled
                    labelId="origen-label"
                    id="origen"
                    value={formData.origen}
                    label="Origen de la falla"
                    size="small"
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value={"Falla interna al adjudicatario"}>Falla interna al adjudicatario</MenuItem>
                    <MenuItem value={"Falla externa al adjudicatario"}>
                      Falla externa al adjudicatario
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid size={6}>
                <FormControl sx={{ width: "100%" }} size="small">
                  <InputLabel
                    id="extension_falla-label"
                    sx={{ backgroundColor: "#fff" }}
                  >
                    Extensión de la falla
                  </InputLabel>
                  <Select
                    required
                    disabled
                    labelId="extension_falla-label"
                    id="extension_falla"
                    value={formData.extension_falla}
                    label="Extensión de la falla"
                    size="small"
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value={"Solo EES"}>Solo EES</MenuItem>
                    <MenuItem value={"Evento mayor"}>
                      Evento mayor
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{}}
            >
              DESCRIPCIÓN DE LA FALLA
            </Typography>


            <FormControl sx={{ width: "100%", maxWidth: "100%" }}>
              <TextField
                required
                multiline
                disabled
                minRows={4}
                maxRows={30}
                id="outlined-required"
                label=""
                placeholder="Texto de falla indicada por el Operador"
                size="small"
                value={formData.texto_falla_operador}
              />
            </FormControl>

            <Grid container spacing={2}>
            <Grid size={8}>
              <TextField
                disabled
                size="small"
                variant="outlined"
                placeholder="Subir evidencia (formato PDF)"
                value={formData.evidence}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{marginLeft: "10px"}}>
                      <ImageSearchIcon sx={{color: 'black'}}/>
                    </InputAdornment>
                  ),
                  readOnly: true,
                }}
                sx={{ width: "100%", maxWidth: "100%" }}
                readOnly
              />
            </Grid>
            <Grid size={4}>
              <Button fullWidth variant="text" disabled={formData.evidence && formData.evidence.length > 0 ? false : true} color="primary" onClick={() => window.open(formData.evidence[0], "_blank")} startIcon={<FileDownload />} >
                Descargar evidencia
              </Button>
            </Grid>
          </Grid>
          </Stack>

        </form>
          </Box>
          <Stack sx={{paddingLeft: "9rem", paddingRight: "9rem", paddingBottom: "3rem" }}>
                    <Grid container spacing={2} sx={{paddingTop: '2rem'}}>

             {submitConfirm ?
                <Grid display="flex" justifyContent="center" alignItems="center" size={12} sx={{paddingBottom: "3rem"}}>
                    <Alert icon={false} variant="filled" severity="warning" display="flex" justifyContent="center" sx={{ display: "flex", justifyContent: "center", '& .MuiAlert-message': { textAlign: "center", width: "inherit" } }}>
                        <Typography
                            variant="body"
                            color="inherit"
                            sx={{ textAlign: "center" }}
                        >
                            Revise que todos los campos contienen la información correcta antes de firmar la Contingencia. Al hacer clic en 'Firmar y enviar', el documento quedará firmado sin opción de modificación.
                        </Typography>
                    </Alert>
                </Grid>
                :
                ""
            }       
            <Grid size={2}></Grid>
            <Grid size={4}>
              <PageButtonWhite onClick={rejectDoc} loading={loadingButton} width="12rem"> Rechazar</PageButtonWhite>
            </Grid>
            <Grid size={4}>
              <PageButtonBlack onClick={singDoc} loading={loadingButton} justifyContent="center" width="12rem">
                Firmar y enviar
              </PageButtonBlack>
            </Grid>
            <Grid size={2}></Grid>
          </Grid>
          </Stack>
      </PageBgBlock>
      </>
       :

        <PageBgBlock pad="4rem 9rem">

            <Box align="center">
              <ErrorOutlineIcon color="error" sx={{ fontSize: 45 }} />
            </Box>
            <Typography
            align="center"
              variant="h1"
              component="h1"
              fontWeight="bold"
              color="textPrimary"
              gutterBottom
            >
              No disponible
            </Typography>

            <Box
              justifyContent="center"
              sx={{ maxWidth: "500px", margin: "0 auto", display: "flex" }}
            >
              <Typography
                variant="body"
                align="center"
                sx={{ marginBottom: "20px" }}
              >
                El documento al que intenta acceder no esta diponible para ser firmado.
              </Typography>
            </Box>
            </PageBgBlock>
      
    )}
    <ModalStandard open={open} error={modalError} title={modalContent} btnText="Volver a listado" onClick={() => navigate("/contingencias_lista")} onClose={handleClose}></ModalStandard>
      
    </PageBody>
              </>
    );
  }
}
