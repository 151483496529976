import { Box, Button } from "@mui/material";

import Grid from '@mui/material/Grid2';
import Typography from "@mui/material/Typography";

export default function SignatureFooter(props) {


    return (
        <Grid container spacing={2} sx={{ paddingBottom: "15px", paddingTop: "80px" }}>
          <Grid size={4} align="center">
             <Box
                  sx={{
                    borderBottom: "2px solid black",
                    width: "60%",
                    margin: "0 auto",
                    paddingTop: "80px",
                  }}
                />
            <Typography variant="small" sx={{  }} >
              Firma Operador
            </Typography>
          </Grid>
          <Grid size={4} align="center">
                       <Box
                  sx={{
                    borderBottom: "2px solid black",
                    width: "60%",
                    margin: "0 auto",
                    paddingTop: "80px",
                  }}
                />
            <Typography variant="small" sx={{  }} >
              Firma EES
            </Typography>
        </Grid>
          <Grid size={4} align="center">
                       <Box
                  sx={{
                    borderBottom: "2px solid black",
                    width: "60%",
                    margin: "0 auto",
                    paddingTop: "80px",
                  }}
                />
            <Typography variant="small" sx={{  }} >
              Firma Subtel
            </Typography>    
          </Grid>
          <Grid size={4} ></Grid>
          <Grid size={4} align="center">
                       <Box
                  sx={{
                    borderBottom: "2px solid black",
                    width: "60%",
                    margin: "0 auto",
                    paddingTop: "80px",
                  }}
                />
            <Typography variant="small" sx={{  }} >
              Firma Mineduc
            </Typography>
          </Grid>
          <Grid size={4}>
            
          </Grid>
            
        </Grid>
    );
}
