import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

import {NO_EES, NO_EES_TEMP, NO_SUBTEL, NO_MINEDUC} from "../../Constants/Constants";


const STEPS_TEMP = [
    {   
        step: "FAS",
        label: 'Levantamiento de FAS',
        description: 'Falta validación y firma del representante del EES. Las personas encargadas de firmar son:',
    },
    {
        step: "EES",
        label: 'Firma EES',
        description:
            'Fue necesario integrar un representante temporal, el cual ya firmó y se espera ser validado por Mineduc. Las personas encargadas de firmar son:',
    },
    {
        step: "SUBTEL",
        label: 'Firma SUBTEL',
        description: 'Al quedar registrada la firma de SUBTEL el convenio ya está activado y comenzarán a correr los acuerdos. Las personas encargadas firmar son:',
    },
    {
        step: "MINEDUC",
        label: 'Validación MINEDUC',
        description: 'En el caso de haber incluído la firma de un Responable Temporal MINEDUC queda notificado y lo debe validar junto con ingresarlo a sus registros. Las personas encargadas firmar son:',
    },
];

const STEPS_NO_TEMP = [
    {   
        step: "FAS",
        label: 'Levantamiento de FAS',
        description: 'Falta validación y firma del representante del EES. Las personas encargadas de firmar son:',
    },
    {
        step: "EES",
        label: 'Firma EES',
        description:
            'Falta validación y firma del representante del EES. Las personas encargadas de firmar son:',
    },
    {
        step: "SUBTEL",
        label: 'Firma SUBTEL',
        description: 'Al quedar registrada la firma de SUBTEL el convenio ya está activado y comenzarán a correr los acuerdos. Las personas encargadas firmar son:',
    }
];

export default function ListStepper(props) {
    const { row } = props;
    const [progress, setProgress] = useState({"FAS": 0, "EES": 0, "SUBTEL": 0, "MINEDUC": 0 })
    const [steps, setSteps] = useState(STEPS_NO_TEMP);
    const [currSteps, setCurrSteps] = useState("FAS");

    const [actualStep, setActualStep] = useState(4);

    const defineStep = () => {
        if (row.estado === NO_EES || row.estado === NO_EES_TEMP){
            setCurrSteps("EES");
            if (row.firmas.some(firma => firma.rol_firma.includes("Temporal"))){
                setSteps(STEPS_TEMP);
            }
            setActualStep(1)
        }else if (row.estado === NO_SUBTEL){
            setCurrSteps("SUBTEL");
            if (row.firmas.some(firma => firma.rol_firma.includes("Temporal") && firma.estado_firma === "Firmado")){
                setSteps(STEPS_TEMP);
            }

            setActualStep(2)
        }else if (row.estado === NO_MINEDUC){
            setCurrSteps("MINEDUC");
            setSteps(STEPS_TEMP);
            setActualStep(3)
        }
    }

    const setEffectiveProgress = () => {
        
        row.progress.steps.map((step, index) => {
            
            if (progress[step.institution.type] !== undefined) {
                if (step.type !== "Firmado") {
                    progress[step.institution.type] = 1
                }
            }
        })
    }

    const [activeStep, setActiveStep] = React.useState(actualStep);

    useEffect(() => {
        setEffectiveProgress()
        defineStep()
    }, []);

    useEffect(() => {
        setActiveStep(actualStep)
        defineStep()
    }, [actualStep]);


return (
    <Box sx={{ maxWidth: 400 }}>
        <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
                <Step key={step.label}>
                    <StepLabel
                        optional={
                            index === steps.length - 1 ? (
                                <Typography variant="caption">Último Paso</Typography>
                            ) : null
                        }
                    >
                        {step.label}
                    </StepLabel>
                    <StepContent>
                        <Typography>{step.description}</Typography>

                     <List sx={{ width: '100%' }}>
                      {(() => {
                        const incompleteSteps = row.progress.steps.filter(
                          (stepProgress) =>
                            stepProgress.institution.name.includes(currSteps)
                        );

                        return incompleteSteps.length > 0
                          ? incompleteSteps.flatMap((step, stepIndex) =>
                              step.in_charge.map((person, index) => (
                                <ListItem key={`${stepIndex}.${index}.item`}>
                                  <ListItemAvatar key={`${stepIndex}.${index}.avatar`}>
                                    <Avatar key={`${stepIndex}.${index}.aavatar`}>
                                      <AccountBoxIcon key={`${stepIndex}.${index}.icon`} />
                                    </Avatar>
                                  </ListItemAvatar>
                                  <ListItemText
                                    primaryTypographyProps={{ style: { color: "#111" } }}
                                    key={`${stepIndex}.${index}.text`}
                                    primary={person}
                                    secondary={step.institution.name}
                                  />
                                </ListItem>
                              ))
                            )
                          : null;
                      })()}
                        </List>
                    </StepContent>
                </Step>
            ))}
        </Stepper>
        {activeStep === steps.length && (
            <Paper square elevation={0} sx={{ p: 3 }}>
                <Typography>Todos los pasos completados.</Typography>
            </Paper>
        )}
    </Box>
);
}