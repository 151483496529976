import React, { useState } from 'react';
import { Dialog, DialogContent, Grid, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const ImageGallery = ({ images }) => {
  const [open, setOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const handleOpen = (index) => {
    setSelectedImageIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImageIndex(null);
  };

  const handlePrev = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handleNext = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <div>
      <Grid container spacing={2}>
        {images.map((image, index) => (
          <Grid item xs={4} sm={3} md={2} key={index}>
            <img
              src={image}
              alt={`image-${index}`}
              style={{ width: '100%', cursor: 'pointer' }}
              onClick={() => handleOpen(index)}
            />
          </Grid>
        ))}
      </Grid>

      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogContent sx={{ padding: 0 }}>
          <Box sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
            <IconButton
              onClick={handleClose}
              style={{
                position: 'absolute',
                top: 8,
                right: 8,
                zIndex: 1,
                color: 'black',
              }}
            >
              <CloseIcon />
            </IconButton>
            
            <IconButton
              onClick={handlePrev}
              style={{
                position: 'absolute',
                left: 8,
                zIndex: 1,
                color: 'black',
              }}
            >
              <ArrowBackIosIcon />
            </IconButton>

            <img
              src={images[selectedImageIndex]}
              alt="Selected"
              style={{ width: '100%', marginBottom: 0, objectFit: 'contain' }}
            />

            <IconButton
              onClick={handleNext}
              style={{
                position: 'absolute',
                right: 8,
                zIndex: 1,
                color: 'black',
              }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ImageGallery;
