import React, { useState, useContext, useEffect } from "react";
import { Box, Typography, Button, Paper, Checkbox } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { Download as DownloadIcon } from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import GeneralContext from "../GeneralContext/GeneralContext";
import InputFilter from "../GeneralComponents/InputFilter";
import axios from "../../api/axios";
import PageBody from "../GeneralComponents/PageBody";
import PageButtonWhite from "../GeneralComponents/PageButtonWhite";
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import { CircularProgress } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ContingenciasList from "../Contingencias/ContingenciasList";
import ListEmpty from "../GeneralComponents/ListEmpty";
import * as XLSX from "xlsx";
import Grid from "@mui/material/Grid2";
import Modal from '@mui/material//Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from "@mui/icons-material/Close";


import {ROL_VAL_SUBTEL, ROL_ADMIN_SUBTEL, ROL_OP_ADMIN, ROL_ADMIN} from "../../Constants/Constants";


let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);

const columns = [
  {
    id: "checkbox",
    label: "",
    width: "5%",
    minWidth: "50px",
    align: "center",
  },
  { id: "rbd", label: "RBD", width: "12%", minWidth: "100px", align: "center" },
  { id: "id_operador", label: "Operador", width: "12%", minWidth: "100px" },
  { id: "id_zona", label: "Zona", width: "12%", minWidth: "100px" },
  {
    id: "nombre_comuna",
    label: "Comuna",
    width: "12%",
    minWidth: "100px",
    align: "left",
  },
  {
    id: "periodo_facturacion",
    label: "Periodo",
    width: "12%",
    minWidth: "100px",
    align: "left",
  },
  {
    id: "url",
    label: "Descargar XLS",
    width: "12%",
    minWidth: "100px",
    align: "left",
  },
  {
    id: "link_prefactura_sagec",
    label: "Prefactura SAGEC",
    width: "12%",
    minWidth: "100px",
    align: "left",
  },
  {
    id: "link_prefactura_sagec_adicional",
    label: "Prefactura Operador",
    width: "12%",
    minWidth: "100px",
    align: "left",
  },
  {
    id: "diff_action",
    label: "Acepto diferencia",
    width: "12%",
    minWidth: "120px",
    align: "left",
  },
  {
    id: "diff",
    label: "Diferencia prefactura",
    width: "12%",
    minWidth: "100px",
    align: "left",
  },
  {
    id: "estado_validacion",
    label: "Estado",
    width: "12%",
    minWidth: "140px",
    align: "left",
  },
  {
    id: "codigo",
    label: "Código pre-factura",
    width: "12%",
    minWidth: "100px",
    align: "left",
  },
];

export default function PrefacturaList() {
  const [rows, setRows] = useState([]);
  const { includeRol, pageTitle, setPageTitle } = useContext(GeneralContext);
  const [filters, setFilters] = useState({});
  const [tableData, setTableData] = useState(rows);
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredColumns, setFilteredColumns] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const navigate = useNavigate();



  const [activeRow, setActiveRow] = useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = (row) => {setActiveRow(row); setOpenModal(true); }
  const handleCloseModal = () => setOpenModal(false);

  const fetchData = async (query) => {
    setLoadingTable(true);
    axios
      .getPrefacturas(query)
      .then((response) => {
        const modifiedData = response.data.map((row, index) => {
          return {
            ...row,
            diff: row["diferencia"] ? "Con diferencia" : "-",
            codigo: "-",
            diff_action: "",
            estado_validacion: row["estado_validacion"] ? row["estado_validacion"] : "Pendiente",
            periodo_facturacion: row['fecha_reporte'].slice(0, 7), 
            index: index + 1,
          };
        });
        setRows(modifiedData);
        setTableData(modifiedData);
      })
      .catch((error) => {})
      .finally(() => {
        setLoadingTable(false);
      });

     if (
      includeRol([ROL_OP_ADMIN ])
    ) {
      const columnOrder = [
      "checkbox",
      "periodo_facturacion",
      "id_zona",
      "link_prefactura_sagec",
      "link_prefactura_sagec_adicional",
      "diff",
      "diff_action",
      "estado_validacion",
      "codigo",
    ];

    setFilteredColumns(
      columnOrder
        .map((id) => columns.find((column) => column.id === id))
        .filter(Boolean)
    );
    } else if (includeRol([ROL_VAL_SUBTEL, ROL_ADMIN_SUBTEL, ROL_ADMIN])) {
       const columnOrder = [
      "checkbox",
      "periodo_facturacion",
      "id_operador",
      "id_zona",
      "link_prefactura_sagec",
      "link_prefactura_sagec_adicional",
      "diff",
      "estado_validacion",
      "codigo",
    ];

    setFilteredColumns(
      columnOrder
        .map((id) => columns.find((column) => column.id === id))
        .filter(Boolean)
    );

    

    setFilteredColumns(
      columnOrder
        .map((id) => columns.find((column) => column.id === id))
        .filter(Boolean)
    );
  };
}
const acceptPrefacture = () => {
  handleCloseModal();

  axios
      .acceptPrefactura(activeRow["id"])
      .then((response) => {
      })
      .catch((error) => {})
      .finally(() => {
        setLoadingTable(false);
        fetchData();
      });

  
}
const downloadXLS = () => {
  const selectedRows = tableData.filter((row) => selected.includes(row.index));

  if (selectedRows.length === 0) {
    alert("No hay filas seleccionadas para descargar.");
    return;
  }

  const filteredData = selectedRows.map((row) => ({
    rbd: row.rbd,
    id_zona: row.id_zona,
    diff: row.diff,
    codigo: row.codigo,
  }));

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Tabla");
    XLSX.writeFile(workbook, "tabla.xlsx");
  };

  useEffect(() => {
    fetchData("");
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const rejectDocs = () => {
    alert(selected)
  };
  const aproveDocs = () => {
    alert(selected)
  };
  const downloadDocs = () => {
    alert(selected)
  };

  const filterRecords = (name: String, value: any) => {
    let filterObj = { ...filters };
    filterObj[name] = value;
    setFilters(filterObj);
    if (value == null) {
      delete filterObj[name];
    }
    if (Object.keys(filterObj).length !== 0) {
      setTableData([
        ...rows.filter((item) =>
          Object.entries(filterObj).every(([key, value]) => item[key] === value)
        ),
      ]);
    } else {
      setTableData(rows);
    }
  };

      const downloadPDF = (url) => {
      setLoadingPDF(true);
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.blob();
            })
            .then((blob) => {
                const blobUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');

                a.href = blobUrl;
                a.download = 'download.pdf';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(blobUrl);
                setLoadingPDF(false);
            })
            .catch((error) => {setLoadingPDF(false); console.error('Error al descargar el archivo:', error); window.open(url, '_blank'); });
    };

  const downloadReports = () => {
    setLoading(true);
    alert("Descargar consolidado");
    setLoading(false);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelecteds = tableData
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((n) => n.index);
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
  };

  const handleCheckboxClick = (index) => {
    const selectedIndex = selected.indexOf(index);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, index);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (index) => selected.indexOf(index) !== -1;

  useEffect(() => {
    setPageTitle("Listado de reportes");
  }, [pageTitle, setPageTitle]);

  return (
    <>
     {includeRol([ROL_VAL_SUBTEL, ROL_ADMIN_SUBTEL, ROL_ADMIN]) ? (<ContingenciasList></ContingenciasList>) : null}
    <PageBody>

      <Grid
        container
        spacing={1}
        columns="10"
        sx={{ marginBottom: "28px", marginTop: "-1rem" }}
      >
        <Grid item size={2}>
          <InputFilter
            data={rows}
            item={"periodo_facturacion"}
            name={"Periodo"}
            callback={filterRecords}
          />
        </Grid>
        {includeRol([ROL_VAL_SUBTEL, ROL_ADMIN_SUBTEL, ROL_ADMIN]) ? (<Grid item size={2}>
          <InputFilter
            data={rows}
            item={"id_operador"}
            name={"Operador"}
            callback={filterRecords}
          />
        </Grid>) : null}
        <Grid item size={2}>
          <InputFilter
            data={rows}
            item={"id_zona"}
            name={"Zona"}
            callback={filterRecords}
          />
        </Grid>
        <Grid item size={2}>
          <InputFilter
            data={rows}
            item={"diff"}
            name={"Diferencia"}
            callback={filterRecords}
          />
        </Grid>
        <Grid item size={2}>
          <InputFilter
            data={rows}
            item={"estado_validacion"}
            name={"Estado"}
            callback={filterRecords}
          />
        </Grid>
      </Grid>

      {loadingTable ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
            width: "100%",
            backgroundColor: "white",
          }}
        >
          <CircularProgress />
          <Typography variant="h6" sx={{ marginTop: 2 }}>
            Cargando...
          </Typography>
        </Box>
      ) : (
        <>
          <Paper
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
              background: "#FFFFFF",
              borderRadius: "0.25rem",
              marginBottom: "0.75rem",
            }}
          >
            <Typography
              variant="h1"
              color="inherit"
              noWrap
              sx={{ textAlign: "center", padding: "0.5rem" }}
            >
              Validación Pre-Factura
            </Typography>
          </Paper>

          <PageBgBlock pad="0">
            <Paper
              sx={{
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
                background: "#FFFFFF",
                borderRadius: "0.25rem",
                marginBottom: "2rem",
              }}
            >
              <TableContainer >
                <Table size="small" aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {filteredColumns.map((column, index) => (
                        <TableCell
                          key={"CL" + index}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.id === "checkbox" ? (
                            <Box display="flex" alignItems="center">
                              <Checkbox
                                color="primary"
                                indeterminate={
                                  selected.length > 0 &&
                                  selected.length < tableData.length
                                }
                                checked={
                                  tableData.length > 0 &&
                                  selected.length === tableData.length
                                }
                                onChange={handleSelectAll}

                                inputProps={{ "aria-label": "select all" }}
                              />

                              {column.label}
                            </Box>
                          ) : (
                            column.label
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.index);

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={"TR" + index}
                            aria-checked={isItemSelected}
                            key={row.index}
                            selected={isItemSelected}
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                          >
                            {filteredColumns.map((column, index) => {
                              const value = row[column.id];
                              return (
                                <TableCell
                                  key={"TC" + index}
                                  align={column.align}
                                >
                                  {column.id.startsWith("link_prefactura_sagec") ? (
                                    <Button
                                      disabled={loadingPDF}
                                      onClick={(e) => { e.stopPropagation();
                                                  downloadPDF(value)
                                                }}
                                      key={`${column.id}.button.${index}`}
                                      variant="text"
                                      startIcon={
                                        <DownloadIcon
                                          key={`${column.id}.icon.${index}`}
                                          sx={{ color: "#111" }}
                                        />
                                      }
                                    >
                                      <Typography
                                        key={`${column.id}.typ.${index}`}
                                        sx={{
                                          color: "#111",
                                          textTransform: "capitalize",
                                          textDecoration: "underline",
                                        }}
                                      >
                                        Descargar
                                      </Typography>
                                    </Button>
                                  ) : column.id==="diff_action" ? (
                                  <Stack>
					                <Grid container spacing={1} >
						            <Grid item xs={6} sx={{display:'flex'}}>
					                  <PageButtonWhite loading={row["estado_validacion"] !== "Pendiente"} width="2.5rem" pad="0 1rem"
					                    onClick={(e) => { 
					                      e.stopPropagation();
					                      handleOpenModal(row);
					                    }}
					                  >
					                      Si
					                  </PageButtonWhite>
						            </Grid>
						            <Grid item xs={6} sx={{display:'flex'}}>
					                  <PageButtonWhite loading={row["estado_validacion"] !== "Pendiente"} width="2.5rem" pad="0 1rem"
					                    onClick={(e) => { 
					                      e.stopPropagation();
					                      navigate('/apelacion/'+row["id"]);
					                    }} 
					                  > No
					                  </PageButtonWhite>
						            </Grid>
						            </Grid>
						            </Stack>
                ) : column.id === "checkbox" ? (
                                    <Checkbox
                                      color="primary"
                                      checked={isItemSelected}
                                      onClick={() =>
                                        handleCheckboxClick(row.index)
                                      }
                                      inputProps={{
                                        "aria-labelledby": row.index,
                                      }}
                                    />
                                  ) : column.id === "estado_validacion" ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexWrap: "no-wrap",
                                      }}
                                    >
                                      <FiberManualRecordIcon
                                        sx={{
                                          fontSize: "0.75rem",
                                          color:
                                            value === "En Apelación" || value === "Pendiente"
                                              ? "#FFE263"
                                              : value === "Rechazado"
                                              ? "#FB3B3B"
                                              : "#41AE43",
                                          marginRight: "0.5rem",
                                        }}
                                      />

                                      {value}
                                    </div>
                                  ) : (
                                    value
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
                      {tableData.length == 0 ? (<ListEmpty></ListEmpty>) : null}
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </PageBgBlock>

           {includeRol([ROL_VAL_SUBTEL, ROL_ADMIN_SUBTEL]) ? (<Grid container spacing={2} selectedIndex={{display: "flex"}}>
          <Grid size={3}>
           
          </Grid>
          <Grid size={5}>
            <PageButtonWhite
              onClick={downloadXLS}
              width="100%"
            >
              Descarga selección de documentos de apelación (.zip)
            </PageButtonWhite>
          </Grid>
          <Grid size={4}>
            <PageButtonBlack
            color="white"
              onClick={aproveDocs}
              width="100%"
            >
              Aprobar pre-factura y generar código
            </PageButtonBlack>
          </Grid>
          <Grid size={4}>
           
          </Grid>
        </Grid>) : 

        (<Grid container spacing={2} selectedIndex={{display: "flex"}}>
          <Grid size={7}>
           
          </Grid>
          <Grid size={5}>
            <PageButtonWhite
              onClick={downloadXLS}
              width="100%"
            >
              Descarga selección de documentos de apelación (.zip)
            </PageButtonWhite>
          </Grid>
          
           
          </Grid>)

           }

        </>
      )}


                     <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(5px)",
          backgroundColor: "rgba(0, 0, 0, 0.05)",
        }}
        slotProps={{ backdrop: { sx: { opacity: "0.1!important" } } }}
      >
        <Box
          sx={{
            position: "absolute",
            width: "749px",
            height: "261px",
            backgroundSize: "background.paper",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            outline: 0,
          }}
        >
          <Paper elevation={3} sx={{ padding: 3, textAlign: "center" }}>
            <IconButton
              sx={{ position: "absolute", top: 8, right: 8 }}
              onClick={handleCloseModal}
            >
              <CloseIcon />
            </IconButton>

      
            <Typography
              variant="h5"
              component="h1"
              fontWeight="bold"
              color="textPrimary"
              gutterBottom
              sx={{
                fontSize: "26px",
                lineHeight: "66px",
                margin: "0",
                color: "#333",
              }}
            >
              ¿Desea continuar?
            </Typography>

            <Box justifyContent="center" sx={{ maxWidth: "400px", margin: "0 auto", display: "flex" }} >
              <Typography variant="body" align="center" >
              Esta por aceptar la prefactura calculada por SAGEC para la <b>zona {activeRow["id_zona"]}</b> en el periodo <b>{activeRow["periodo_facturacion"]}</b>. Una vez aceptada, esta accion no podrá ser revertida.
              </Typography>
            </Box>
            <Grid container spacing={2} sx={{ paddingTop: "2rem" }}>
              <Grid size={3}></Grid>
                <Grid size={3}>

            <PageButtonWhite width="10rem" onClick={handleCloseModal}>
              Cancelar
            </PageButtonWhite>

          </Grid>
                <Grid size={3}>
            <PageButtonBlack width="10rem" onClick={acceptPrefacture}>
              Aceptar prefactura
            </PageButtonBlack>
          </Grid>
          </Grid>
          </Paper>
        </Box>
      </Modal>

    </PageBody>
    </>
  );
}
