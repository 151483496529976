import { Box, Button } from "@mui/material";

export default function PageButtonWhite(props) {

    const fs = props.fs ? props.fs : "14px";
    const pad = props.pad ? props.pad : "4px 16px";
    const width = props.width ? props.width : '300px';
    const icon = props.icon ? props.icon : '';
    const startIcon = props.startIcon ? props.startIcon : '';
    const bgColor = props.bgColor ? props.bgColor : '#FFF';
    const style = props.style ? props.style : 'primary';

    return (
    	<Box sx={{width: width, display: 'block', margin:'auto'}}>
        {props.onClick ? 
        <Button color={style} variant="text"  onClick={props.onClick} startIcon={startIcon} endIcon={icon} disabled={props.loading} sx={{backgroundColor: bgColor, textTransform: 'none', width: width,  borderRadius: '4px', fontSize: fs, padding: pad, fontWeight: '500', margin: 'auto'}}>
            {props.loading ? 'Cargando...' : props.children}
        </Button> 
        :
        <Button color={style} variant="text"  type="submit" startIcon={startIcon} endIcon={icon} disabled={props.loading} sx={{backgroundColor: bgColor, textTransform: 'none', width: width, borderRadius: '4px', fontSize: fs, padding: pad, fontWeight: '500'}}>
            {props.loading ? 'Cargando...' : props.children}
        </Button>}
        </Box>
       
    );
}

