import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import GeneralContext from "../GeneralContext/GeneralContext";
import axios from "../../api/axios";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import TextField from "@mui/material/TextField";

import FormControl from "@mui/material/FormControl";
import PageBody from "../GeneralComponents/PageBody";
import PageButtonBlack from "../GeneralComponents/PageButtonBlack";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import Alert from '@mui/material/Alert';

import ModalStandard from "../GeneralComponents/ModalStandard";
import InputFile from "../GeneralComponents/InputFile";
import HourglassLoader from "../GeneralComponents/HourglassLoader";


export default function Apelacion() {
  const { userSesion, setPageTitle, setDrawerHidding } = useContext(GeneralContext);
  //const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const [modalError, setModalError] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [submitConfirm, setSubmitConfirm] = useState(false);
  const rutRef = useRef(null);    
  const [evError, setEvError] = useState(false); 
  const [evidence, setEvidence] = useState(null);
  const [documentVisible, setDocumentVisible] = useState(true);
    const evidenceRef = useRef(null); 

  const apelacionRef = useRef();
  const { id } = useParams();

  const [formData, setFormData] = useState({
    id: id,
    id_operador: "",
    operator_name: "",
    zona: "",
    id_zona: "",
    periodo: "",
    motivo: "",
    evidence: [],
  });



  const setFile = (archivo) => {
    setEvError(!archivo ? true : false);
    setEvidence((prevData) => ({
      ...prevData,
      archivo,
    }));
    console.log(archivo);
  };
const fetchData = async (query) => {
  setLoading(true);
  try {
    const response = await axios.getPrefactura(id);
    setFormData((prevFormData) => ({
      ...prevFormData,
      periodo: response.data['fecha_reporte'].slice(0, 7),
      id_zona: response.data.id_zona+"",
      zona: response.data.id_zona+"",
    }));
  } catch (error) {
    console.error("Error al obtener los datos:", error);
    setDocumentVisible(false);
  } finally {
    setLoading(false);
  }
};

function getOperator() {
  axios.getOperator(userSesion.rut)
    .then((response) => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        operator_name: response.data.operador,
        id_operador: response.data.id_operador + "",
      }));
    })
    .catch((error) => {
      console.error("Error al obtener el operador:", error);
    });
}

  const save = async (event) => {
    event.preventDefault();
    if (!evidence){
        setEvError(!evidence ? true : false);
        evidenceRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        return;
    }

    if (!submitConfirm) {
        setSubmitConfirm(!submitConfirm)
        return;
    }
    handleDownloadPdf()
  };

  const handleDownloadPdf = () => {
    const element = apelacionRef.current;

    // Renderiza el elemento en un canvas y maneja la promesa con .then() y .catch()
    html2canvas(element, { scale: 2 })
        .then((canvas) => {

            setLoading(true);
            //const imageData = canvas.toDataURL('image/png');
            const imageData = canvas.toDataURL('image/jpeg', 1);
           
            const pdfWidth = 210;
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
            const pdf = new jsPDF('p', 'mm', [pdfWidth, pdfHeight + 60]);

     
            pdf.setFillColor(255, 255, 255);

            pdf.rect(0, 0, pdfWidth, pdfHeight + 140, 'F');
            pdf.addImage(imageData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            
            const pdfBlob = pdf.output('blob');

            const form = new FormData();
            const localFormData = {
                ...formData,
                files: evidence && evidence.archivo ? [evidence.archivo] : [],
            }

            form.append("data", JSON.stringify(localFormData));
            
            for (let i = 0; i < localFormData["files"].length; i++) {
              console.log(localFormData["files"][i]);
                form.append("evidence", localFormData["files"][i]);
            }
            
            form.append("file", pdfBlob, "temp.pdf");

            axios.sendFilesApelacion(form)
                .then((response) => {
                    setLoading(false);
                    setSubmitConfirm(!submitConfirm)
                    setModalError(false);
                    setModalContent("Documento enviado y firmado");
                    setOpen(true);
                })
                .catch((error) => {
                    setModalError(true);
                    setModalError("La creacion del documento a fallado");
                    setLoading(false);

                })
        })
        .catch((error) => {
            setLoading(false);
            console.error('Error generando el PDF:', error);
            throw error;
        });
};




  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

      useEffect(() => {
        setDrawerHidding(false)
    }, [setPageTitle, setDrawerHidding]);


      useEffect(() => {
        getOperator();
        fetchData();
        setDrawerHidding(false)
    }, []);

  return (
    <PageBody>
      <PageBgBlock pad="0" >
      { loading ? (<HourglassLoader/>) : (<Box><Box ref={apelacionRef} sx={{ paddingLeft: "9rem", paddingRight: "9rem", paddingTop: "2rem"}}> 
        <Typography
          variant="h1"
          align="center"
          sx={{ marginBottom: "0.24rem" }}
        >
          FORMULARIO APELACIÓN PRE-FACTURA
        </Typography>
        <Box 
          justifyContent="center"
          sx={{ maxWidth: "100%", margin: "0 auto", display: "flex" }}
        >
          <Typography
            variant="body"
            align="center"
            sx={{ marginBottom: "2rem" }}
          >
                  En este formulario deberá justificar su factura proporcionando una descripción clara del motivo de la discrepancia. Es importante incluir toda la evidencia relevante que respalde su apelación, como documentos, correos electrónicos o comprobantes, para que su caso pueda ser evaluado de manera justa y objetiva.
          </Typography>
        </Box>

        <form onSubmit={save} id="contingencia">
          <Stack spacing={4} sx={{ marginBottom: "2rem" }}>

            <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              DATOS DEL OPERADOR
            </Typography>

            <Grid container spacing={2}>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    required
                    name="id_operador"
                    label="ID Operador"
                    size="small"
                    value={formData.id_operador}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Grid>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    disabled
                    required
                    name="operator_name"
                    label="Nombre Operador"
                    size="small"
                    value={formData.operator_name}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Grid>
            </Grid>



            <Grid container spacing={2}>

              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                    <TextField
                        required
                        disabled
                        label="Periodo"
                        size="small"
                        value={formData.periodo}
                        sx={{ width: "100%" }}
                    />
                </FormControl>

                            </Grid>
              <Grid size={6}>
                <FormControl fullWidth variant="outlined">
                    <TextField
                        required
                        disabled
                        label="Zona"
                        size="small"
                        value={formData.zona}
                        sx={{ width: "100%" }}
                    />
                </FormControl>
              </Grid>

                            

            </Grid>

          
            
            <Typography
              variant="h3"
              color="inherit"
              noWrap
              sx={{}}
            >
              DESCRIPCIÓN DE LA APELACIÓN
            </Typography>

            <FormControl sx={{ width: "100%", maxWidth: "100%" }}>
              <TextField
                required
                multiline
                minRows={4}
                maxRows={30}
                id="outlined-required"
                label=""
                placeholder="Texto de falla indicada por el EES"
                size="small"
                value={formData.motivo}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    motivo: event.target.value,
                  })
                }
              />
            </FormControl>

            <Box ref={evidenceRef}>
            <InputFile onFileSelect={setFile} fileType="pdf" placeholder="Subir evidencia (formato PDF)" />
            <Typography
              variant="small" align="left" sx={{ marginBottom: "18px", color:  evError ? 'red' : '#111' }}
            >
             {evError ? "Debe adjuntar evidencia de la instalación" : "Máximo 25mb"}
            </Typography>
            </Box>
          </Stack>

        </form>
          </Box>
          <Stack sx={{paddingLeft: "9rem", paddingRight: "9rem", paddingBottom: "3rem" }}>
          <Grid>
            {submitConfirm ?
                <Grid display="flex" justifyContent="center" alignItems="center" size={12} sx={{paddingBottom: "3rem"}} >
                    <Alert icon={false} variant="filled" severity="warning" display="flex" justifyContent="center" sx={{ display: "flex", justifyContent: "center", '& .MuiAlert-message': { textAlign: "center", width: "inherit" } }}>
                        <Typography
                            variant="body"
                            color="inherit"
                            sx={{ textAlign: "center" }}
                        >
                            Revise que todos los campos contienen la información correcta antes de firmar la Apelación. Al hacer clic en 'Firmar y enviar', el documento quedará firmado sin opción de modificación.
                        </Typography>
                    </Alert>
                </Grid>
                :
                ""
            }
          </Grid>

          <PageButtonBlack form="contingencia" loading={loading}> Firmar y enviar</PageButtonBlack>
          </Stack>

          </Box>)}
      </PageBgBlock>
      <ModalStandard open={open} error={modalError} title={modalContent} btnText="Volver a listado" onClick={() => navigate("/apelaciones_lista")} onClose={handleClose}></ModalStandard>
    </PageBody>
  );
}
