import React, { useState, useContext, useEffect } from "react";
import { Box, Typography, Button, Paper, Checkbox } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { Download as DownloadIcon } from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import GeneralContext from "../GeneralContext/GeneralContext";
import InputFilter from "../GeneralComponents/InputFilter";
import axios from "../../api/axios";
import PageBody from "../GeneralComponents/PageBody";
import PageButtonWhite from "../GeneralComponents/PageButtonWhite";
import PageButtonSimple from "../GeneralComponents/PageButtonSimple";
import PageBgBlock from "../GeneralComponents/PageBgBlock";
import { CircularProgress } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ListEmpty from "../GeneralComponents/ListEmpty";
import * as XLSX from "xlsx";
import Grid from "@mui/material/Grid2";

import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';

import {ROL_VAL_SUBTEL, ROL_ADMIN_SUBTEL, ROL_OP_ADMIN, ROL_ADMIN} from "../../Constants/Constants";


let g_start_date = new Date();
g_start_date.setDate(g_start_date.getDate() - 7);

const columns = [
  {
    id: "checkbox",
    label: "",
    width: "5%",
    minWidth: "50px",
    align: "left",
  },
  { id: "rbd", label: "RBD", width: "12%", minWidth: "100px", align: "center" },
  { id: "id_zona", label: "Zona", width: "12%", minWidth: "100px" },
  {
    id: "nombre_ees",
    label: "Nombre",
    width: "12%",
    minWidth: "100px",
    align: "left",
  },
  {
    id: "periodo_alta",
    label: "Fecha de Alta",
    width: "12%",
    minWidth: "100px",
    align: "left",
  },
  {
    id: "periodo_alta",
    label: "Fecha de Baja",
    width: "12%",
    minWidth: "100px",
    align: "left",
  },
  {
    id: "estado",
    label: "Estado",
    width: "12%",
    minWidth: "100px",
    align: "left",
  },
];

export default function ParqueDelete() {
  const [rows, setRows] = useState([]);
  const { includeRol, pageTitle, setPageTitle } = useContext(GeneralContext);
  const [filters, setFilters] = useState({});
  const [tableData, setTableData] = useState(rows);
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredColumns, setFilteredColumns] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const navigate = useNavigate();

  const fetchData = async (query) => {
    setLoadingTable(true);
    axios
      .getPrefacturas(query)
      .then((response) => {
        const modifiedData = response.data.map((row, index) => {
          return {
            ...row,
            estado: "Aprobado",
            diff: "-",
            codigo: "-",
            diff_action: "",
            periodo_facturacion: row['fecha_reporte'].slice(0, 7), 
            index: index + 1,
          };
        });
        setRows(modifiedData);
        setTableData(modifiedData);
      })
      .catch((error) => {})
      .finally(() => {
        setLoadingTable(false);
      });

     if (includeRol([ROL_OP_ADMIN, ROL_VAL_SUBTEL, ROL_ADMIN_SUBTEL, ROL_ADMIN])) {
       const columnOrder = [
      "checkbox",
      "rbd",
      "nombre_ees",
      "id_zona",
      "periodo_alta",
    ];

    setFilteredColumns(
      columnOrder
        .map((id) => columns.find((column) => column.id === id))
        .filter(Boolean)
    );

    

    setFilteredColumns(
      columnOrder
        .map((id) => columns.find((column) => column.id === id))
        .filter(Boolean)
    );
  };
}


  useEffect(() => {
    fetchData("");
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const rejectDocs = () => {
    alert(selected)
  };
  const aproveDocs = () => {
    alert(selected)
  };
  const downloadDocs = () => {
    alert(selected)
  };

  const filterRecords = (name: String, value: any) => {
    let filterObj = { ...filters };
    filterObj[name] = value;
    setFilters(filterObj);
    if (value == null) {
      delete filterObj[name];
    }
    if (Object.keys(filterObj).length !== 0) {
      setTableData([
        ...rows.filter((item) =>
          Object.entries(filterObj).every(([key, value]) => item[key] === value)
        ),
      ]);
    } else {
      setTableData(rows);
    }
  };



  const removeEES = () => {
    setLoading(true);
    alert("Eliminar");
    setLoading(false);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelecteds = tableData
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((n) => n.index);
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
  };

  const handleCheckboxClick = (index) => {
    const selectedIndex = selected.indexOf(index);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, index);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (index) => selected.indexOf(index) !== -1;

  useEffect(() => {
    setPageTitle("Listado de reportes");
  }, [pageTitle, setPageTitle]);

  return (
    <>
      {loadingTable ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
            width: "100%",
            backgroundColor: "white",
          }}
        >
          <CircularProgress />
          <Typography variant="h6" sx={{ marginTop: 2 }}>
            Cargando...
          </Typography>
        </Box>
      ) : (
        <>
          
                        

          <PageBgBlock>

          <Stack alignItems="left" justifyContent="center" spacing={2}>
                <Typography
                  variant="h1"
                  color="inherit"
                  noWrap
                  sx={{ flexGrow: 1, textAlign: "center", fontWeight: 700 }}
                >
                  ELIMINAR EES A ESTADO DE PARQUE
                </Typography>

                <Box justifyContent="center" sx={{ maxWidth: "100%", margin: "0 auto", display: "flex" }} >
                  <Typography
                    variant="body"
                    align="center"
                    sx={{ marginBottom: "3rem", maxWidth: "45rem" }}
                  >
                    Para eliminar establecimientos al Estado de Parque busque el número de RBD, al tener el resultado presione Eliminar.
                  </Typography>
                </Box>
            </Stack>

            <Grid
        container
        spacing={1}
        sx={{ marginBottom: "28px", marginTop: "-1rem" }}
      >
        <Grid item size={3}>
          <InputFilter
            data={rows}
            item={"n_folio"}
            name={"N° Folio"}
            callback={filterRecords}
          />
        </Grid>
        <Grid item size={3}>
          <InputFilter
            data={rows}
            item={"rbd"}
            name={"RBD"}
            callback={filterRecords}
          />
        </Grid>
        
      </Grid>


              <TableContainer >
                <Table size="small" aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {filteredColumns.map((column, index) => (
                        <TableCell
                          key={"CL" + index}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.id === "checkbox" ? (
                            <Box display="flex" alignItems="center">
                              <Checkbox
                                color="primary"
                                indeterminate={
                                  selected.length > 0 &&
                                  selected.length < tableData.length
                                }
                                checked={
                                  tableData.length > 0 &&
                                  selected.length === tableData.length
                                }
                                onChange={handleSelectAll}

                                inputProps={{ "aria-label": "select all" }}
                              />

                              {column.label}
                            </Box>
                          ) : (
                            column.label
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.index);

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={"TR" + index}
                            aria-checked={isItemSelected}
                            key={row.index}
                            selected={isItemSelected}
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                          >
                            {filteredColumns.map((column, index) => {
                              const value = row[column.id];
                              return (
                                <TableCell
                                  key={"TC" + index}
                                  align={column.align}
                                >
                                  { column.id==="diff_action" ? (
                                  <Stack>
					                <Grid container spacing={1} >
						            <Grid item xs={6} sx={{display:'flex'}}>
					                  <PageButtonWhite width="2.5rem" pad="0 1rem"
					                    onClick={(e) => { 
					                      e.stopPropagation();
					                      alert("aceptar")
					                    }}
					                  >
					                      Si
					                  </PageButtonWhite>
						            </Grid>
						            <Grid item xs={6} sx={{display:'flex'}}>
					                  <PageButtonWhite width="2.5rem" pad="0 1rem"
					                    onClick={(e) => { 
					                      e.stopPropagation();
					                      navigate('/apelacion');
					                    }} 
					                  > No
					                  </PageButtonWhite>
						            </Grid>
						            </Grid>
						            </Stack>
                ) : column.id === "checkbox" ? (
                                    <Checkbox
                                      color="primary"
                                      checked={isItemSelected}
                                      onClick={() =>
                                        handleCheckboxClick(row.index)
                                      }
                                      inputProps={{
                                        "aria-labelledby": row.index,
                                      }}
                                    />
                                  ) : column.id === "estado" ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexWrap: "no-wrap",
                                      }}
                                    >
                                      <FiberManualRecordIcon
                                        sx={{
                                          fontSize: "0.75rem",
                                          color:
                                            value === "En Proceso"
                                              ? "#FFE263"
                                              : value === "Aprobado"
                                              ? "#41AE43"
                                              : "#FB3B3B",
                                          marginRight: "0.5rem",
                                        }}
                                      />

                                      
                                      {value}
                                    </div>
                                  ) : (
                                    value
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
                      {tableData.length == 0 ? (<ListEmpty></ListEmpty>) : null}
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />

              
           
          <Box sx={{margin: "auto"}}>
            <PageButtonSimple
                        onClicl={removeEES}
                        startIcon={<RemoveCircleOutlineOutlinedIcon/>}
                        loading={loading}
                        style="warning"
                      >
                        Eliminar establecimiento
                      </PageButtonSimple>
          </Box>
          </PageBgBlock>

           

        </>
      )}

    </>
  );
}
